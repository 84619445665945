import React, { useState, useEffect } from 'react';
import axios from 'axios';
import store from '../store/store';

const createTripID = () => {
  const today = new Date();
  const y = today.getFullYear();
  // JavaScript months are 0-based.
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const h = today.getHours();
  const mi = today.getMinutes();
  const s = today.getSeconds();
  return `TP-HMV-${y}-${m}-${d}-${h}-${mi}-${s}`;
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const CreateBookingForm = () => {
  const [Consignor, setConsignor] = useState('');
  const [Consignee, setConsignee] = useState('');
  const [BookingPoint, setBookingPoint] = useState('');
  const [DeliveryPoint, setDeliveryPoint] = useState('');
  const [NumberOfBox, setNumberOfBox] = useState('');
  const [CN_number, setCN_number] = useState('');
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('############### CREATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log('@@@@@@@@@@@@@ INITIALIZE TO LOGIN ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const userIdRedux = state.auth.userId;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setUserId(userIdRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');
    };
    initialize();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const tripId = createTripID();

    console.log(
      '############### BOOKING DATA ==>',
      tripId,
      Consignor,
      Consignee,
      BookingPoint,
      DeliveryPoint,
      NumberOfBox,
      CN_number,
      userId,
      ' ###############'
    );
    console.log(
      '############### COOKIE TOKEN ==>',
      token,
      userRole,
      ' ###############'
    );
    try {
      const response = await axios.post(
        'https://api.adiexpresslogistics.com/api/v1/trips',
        {
          tripId: tripId,
          Consignor: {
            CompanyName: Consignor,
          },
          Consignee: {
            CompanyName: Consignee,
          },

          BookingPoint,
          DeliveryPoint,
          NumberOfBox,
          CN_number,
          Operator: userId,
        },
        {
          headers: {
            // Authorization: `Bearer ${document.cookie.split('=')[1]}`,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);

      // Show success alert
      alert('Booking is successfully created');
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while creating the booking');
    }
  };

  return (
    <div className="grid-container">
      <div className="bookCard-body">
        {/* <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/air-cargo-100.jpg"
            //   src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div> */}
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/truck-cargo-100.jpg"
            //   src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/img1.webp"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
      </div>
      <div className="bookCard">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="Consignor" className="form-label">
                Consignor
              </label>
              <input
                type="text"
                className="form-control"
                id="Consignor"
                value={Consignor}
                onChange={(e) => setConsignor(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="Consignee" className="form-label">
                Consignee
              </label>
              <input
                type="text"
                className="form-control"
                id="Consignee"
                value={Consignee}
                onChange={(e) => setConsignee(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="bookingPoint" className="form-label">
                Booking Point
              </label>
              <input
                type="text"
                className="form-control"
                id="bookingPoint"
                value={BookingPoint}
                onChange={(e) => setBookingPoint(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="deliveryPoint" className="form-label">
                Delivery Point
              </label>
              <input
                type="text"
                className="form-control"
                id="deliveryPoint"
                value={DeliveryPoint}
                onChange={(e) => setDeliveryPoint(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="NumberOfBox" className="form-label">
                Number of Box
              </label>
              <input
                type="number"
                className="form-control"
                id="NumberOfBox"
                value={NumberOfBox}
                onChange={(e) => setNumberOfBox(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="CN_number" className="form-label">
                CN Number
              </label>
              <input
                type="text"
                className="form-control"
                id="CN_number"
                value={CN_number}
                onChange={(e) => setCN_number(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const DisplayBooking = () => {
  const [cnNumber, setCNNumber] = useState('');
  const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showCNUpdate, setShowCNUpdate] = useState(false);
  const [showVehicleUpdate, setShowVehicleUpdate] = useState(false);
  const [showFinUpdate, setShowFinUpdate] = useState(false);

  console.log('############### CREATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log('@@@@@@@@@@@@@ INITIALIZE TO LOGIN ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');
    };
    initialize();
  }, []);

  const handleCNNumberChange = (event) => {
    setCNNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('################## CALLING DISPLAY ################');
    console.log('##################CN_number==>', cnNumber, '################');
    try {
      const response = await axios.get(
        `https://api.adiexpresslogistics.com/api/v1/trips?CN_number=${cnNumber}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        '################## DISPLAY Response ==>',
        response.data.data[0],
        '################'
      );
      const booking = response.data.data[0];
      setBookingData(booking);
      if (booking.CN_number === cnNumber) {
        setShowForm(true);
      } else {
        alert('An Invalid CN_number is entered, please check you CN_number');
      }
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while Displaying the booking');
    }
  };

  const handleToggleCNForm = () => {
    setShowCNUpdate(!showCNUpdate);
    setShowVehicleUpdate(false);
    setShowFinUpdate(false);
  };
  const handleToggleVehicleForm = () => {
    setShowVehicleUpdate(!showVehicleUpdate);
    setShowCNUpdate(false);
    setShowFinUpdate(false);
  };
  const handleToggleFinForm = () => {
    setShowFinUpdate(!showFinUpdate);
    setShowVehicleUpdate(false);
    setShowCNUpdate(false);
  };

  return (
    <div className="grid-container">
      <div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/login-cargo-100.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/login-cargo-200.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
      </div>
      <div>
        {!showForm ? (
          <div className="bookCard">
            <h2>Enter CN_number</h2>
            <form onSubmit={handleSubmit}>
              <input
                id="form2Example17"
                className="form-control form-control-lg"
                type="Number"
                value={cnNumber}
                onChange={handleCNNumberChange}
              />
              <button className="btn btn-primary" type="submit">
                Submit
              </button>
            </form>
          </div>
        ) : (
          <div>
            <div className="bookCard-header">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <button
                    className="bookNav-link btn btn-primary"
                    onClick={handleToggleCNForm}
                  >
                    Open CN Form
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="bookNav-link btn btn-primary"
                    onClick={handleToggleVehicleForm}
                  >
                    Open Vehicle Form
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="bookNav-link btn btn-primary"
                    onClick={handleToggleFinForm}
                  >
                    Open Finance Form
                  </button>
                </li>
              </ul>
            </div>
            {showCNUpdate && (
              <div className="bookCard">
                <h2>Booking Details</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>Consignor:</td>
                      <td>{bookingData.Consignor.CompanyName}</td>
                    </tr>
                    <tr>
                      <td>Consignee:</td>
                      <td>{bookingData.Consignee.CompanyName}</td>
                    </tr>
                    <tr>
                      <td>Booking Point:</td>
                      <td>{bookingData.BookingPoint}</td>
                    </tr>
                    <tr>
                      <td>Delivery Point:</td>
                      <td>{bookingData.DeliveryPoint}</td>
                    </tr>
                    <tr>
                      <td>Number of Boxes:</td>
                      <td>{bookingData.NumberOfBox}</td>
                    </tr>
                    <tr>
                      <td>CN_number:</td>
                      <td>{bookingData.CN_number}</td>
                    </tr>
                    <tr>
                      <td>TripStatus:</td>
                      <td>{bookingData.TripStatus}</td>
                    </tr>
                    <tr>
                      <td>CNStatus:</td>
                      <td>{bookingData.CNStatus}</td>
                    </tr>

                    <tr>
                      <td>CN_Date:</td>
                      <td>{bookingData.CN_Date}</td>
                    </tr>

                    <tr>
                      <td>BookingDate:</td>
                      <td>{bookingData.BookingDate}</td>
                    </tr>
                    <tr>
                      <td>DeliveryDate:</td>
                      <td>{bookingData.DeliveryDate}</td>
                    </tr>
                    <tr>
                      <td>Invoice_Number:</td>
                      <td>{bookingData.Invoice_Number}</td>
                    </tr>
                    <tr>
                      <td>Invoice_Date:</td>
                      <td>{bookingData.Invoice_Date}</td>
                    </tr>
                    <tr>
                      <td> ETA:</td>
                      <td>{bookingData.ETA}</td>
                    </tr>
                    <tr>
                      <td> EWayBill:</td>
                      <td>{bookingData.EWayBill}</td>
                    </tr>
                    <tr>
                      <td>Packing_Type:</td>
                      <td>{bookingData.Packing_Type}</td>
                    </tr>

                    <tr>
                      <td>Quantity:</td>
                      <td>{bookingData.Quantity}</td>
                    </tr>
                    <tr>
                      <td>Dimension:</td>
                      <td>{bookingData.Dimension}</td>
                    </tr>
                    <tr>
                      <td>Weight:</td>
                      <td>{bookingData.Weight}</td>
                    </tr>
                    <tr>
                      <td>Part_Number:</td>
                      <td>{bookingData.Part_Number}</td>
                    </tr>
                    <tr>
                      <td> ItemDescription:</td>
                      <td>{bookingData.ItemDescription}</td>
                    </tr>
                    <tr>
                      <td> POD_Upload_Date:</td>
                      <td>{bookingData.POD_Upload_Date}</td>
                    </tr>
                    <tr>
                      <td> CustomerCode:</td>
                      <td>{bookingData.CustomerCode}</td>
                    </tr>
                    <tr>
                      <td> DynamicQr:</td>
                      <td>{bookingData.DynamicQr}</td>
                    </tr>
                    <tr>
                      <td> GSTIN:</td>
                      <td>{bookingData.GSTIN}</td>
                    </tr>
                    <tr>
                      <td> PAN:</td>
                      <td>{bookingData.PAN}</td>
                    </tr>
                    <tr>
                      <td> InsurancePolicyNum:</td>
                      <td>{bookingData.InsurancePolicyNum}</td>
                    </tr>
                    <tr>
                      <td> DeclaredInvoiceValue:</td>
                      <td>{bookingData.DeclaredInvoiceValue}</td>
                    </tr>
                    <tr>
                      <td> SaidToContain:</td>
                      <td>{bookingData.SaidToContain}</td>
                    </tr>
                    <tr>
                      <td> DescriptionOfGoods:</td>
                      <td>{bookingData.DescriptionOfGoods}</td>
                    </tr>
                    <tr>
                      <td> SpecialInstructions:</td>
                      <td>{bookingData.SpecialInstructions}</td>
                    </tr>
                    {/* <tr>
                  <td> ModeOfDeliver:</td>
                  <td>{bookingData.ModeOfDeliver}</td>
                </tr>
                <tr>
                  <td> DespatchDetails:</td>
                  <td>{bookingData.DespatchDetails}</td>
                </tr>
                <tr>
                  <td> ModeOfTransportation:</td>
                  <td>{bookingData.ModeOfTransportation}</td>
                </tr> */}
                  </tbody>
                </table>
              </div>
            )}
            {showVehicleUpdate && (
              <div className="bookCard">
                <h2>Booking Details</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>VehicleNumber:</td>
                      <td>{bookingData.VehicleNumber}</td>
                    </tr>
                    <tr>
                      <td>MarketVehicle:</td>
                      <td>{bookingData.MarketVehicle}</td>
                    </tr>
                    <tr>
                      <td>DieselRate Per Litre:</td>
                      <td>{bookingData.DieselRatePerLtrs}</td>
                    </tr>
                    <tr>
                      <td>DieselConsumed:</td>
                      <td>{bookingData.DieselConsumed}</td>
                    </tr>
                    <tr>
                      <td>openingKMs:</td>
                      <td>{bookingData.openingKMs}</td>
                    </tr>
                    <tr>
                      <td>closingKMs:</td>
                      <td>{bookingData.closingKMs}</td>
                    </tr>
                    <tr>
                      <td>TotalKMs:</td>
                      <td>{bookingData.TotalKMs}</td>
                    </tr>
                    <tr>
                      <td>TripMilage:</td>
                      <td>{bookingData.TripMilage}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {showFinUpdate && (
              <div className="bookCard">
                <h2>Booking Details</h2>
                <table>
                  <tbody>
                    <tr>
                      <td>VehicleCharges:</td>
                      <td>{bookingData.VehicleCharges}</td>
                    </tr>

                    <tr>
                      <td>LRCharges:</td>
                      <td>{bookingData.LRCharges}</td>
                    </tr>
                    <tr>
                      <td>GSTCharges:</td>
                      <td>{bookingData.GSTCharges}</td>
                    </tr>
                    <tr>
                      <td> DoorDeliveryCharges:</td>
                      <td>{bookingData.DoorDeliveryCharges}</td>
                    </tr>
                    <tr>
                      <td>DoorPickUpCharges:</td>
                      <td>{bookingData.DoorPickUpCharges}</td>
                    </tr>
                    <tr>
                      <td>LoadingCharges:</td>
                      <td>{bookingData.LoadingCharges}</td>
                    </tr>

                    <tr>
                      <td>UnLoadingCharges:</td>
                      <td>{bookingData.UnLoadingCharges}</td>
                    </tr>

                    <tr>
                      <td>DriverCharges:</td>
                      <td>{bookingData.DriverCharges}</td>
                    </tr>
                    <tr>
                      <td>OtherExpenses:</td>
                      <td>{bookingData.OtherExpenses}</td>
                    </tr>
                    <tr>
                      <td> AdvanceReceived:</td>
                      <td>{bookingData.AdvanceReceived}</td>
                    </tr>
                    <tr>
                      <td>Balance:</td>
                      <td>{bookingData.Balance}</td>
                    </tr>
                    <tr>
                      <td>OfficeCharge:</td>
                      <td>{bookingData.OfficeCharge}</td>
                    </tr>
                    <tr>
                      <td>DriverAdvance:</td>
                      <td>{bookingData.DriverAdvance}</td>
                    </tr>
                    <tr>
                      <td>TollCharges:</td>
                      <td>{bookingData.TollCharges}</td>
                    </tr>
                    <tr>
                      <td>BrokerCommission:</td>
                      <td>{bookingData.BrokerCommission}</td>
                    </tr>
                    <tr>
                      <td>HaltingCharges:</td>
                      <td>{bookingData.HaltingCharges}</td>
                    </tr>
                    <tr>
                      <td>TotalDieselAmount:</td>
                      <td>{bookingData.TotalDieselAmount}</td>
                    </tr>
                    <tr>
                      <td>CashGiven:</td>
                      <td>{bookingData.CashGiven}</td>
                    </tr>
                    <tr>
                      <td>TotalExpense:</td>
                      <td>{bookingData.TotalExpense}</td>
                    </tr>

                    <tr>
                      <td>FinalAmount:</td>
                      <td>{bookingData.FinalAmount}</td>
                    </tr>

                    <tr>
                      <td>TripAmount:</td>
                      <td>{bookingData.TripAmount}</td>
                    </tr>
                    <tr>
                      <td>BillingAmount:</td>
                      <td>{bookingData.BillingAmount}</td>
                    </tr>
                    <tr>
                      <td> GrandTotal:</td>
                      <td>{bookingData.GrandTotal}</td>
                    </tr>
                    <tr>
                      <td>Remarks:</td>
                      <td>{bookingData.Remarks}</td>
                    </tr>
                    <tr>
                      <td>description:</td>
                      <td>{bookingData.description}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
        {/* {bookingData && (
        )} */}
      </div>
    </div>
  );
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const UpdateBooking = () => {
  const [cnNumber, setCNNumber] = useState('');
  const [formData, setFormData] = useState({
    Consignor: '',
    Consignee: '',
    BookingPoint: '',
    DeliveryPoint: '',
    NumberOfBox: '',
    CN_Date: '',
    DeliveryDate: '',
    Invoice_Number: '',
    Invoice_Date: '',
    ETA: '',
    EWayBill: '',
    Packing_Type: '',
    Quantity: '',
    Dimension: '',
    Weight: '',
    Part_Number: '',
    ItemDescription: '',
    POD_Upload_Date: '',
    CustomerCode: '',
    DynamicQr: '',
    GSTIN: '',
    PAN: '',
    InsurancePolicyNum: '',
    DeclaredInvoiceValue: '',
    SaidToContain: '',
    DescriptionOfGoods: '',
    SpecialInstructions: '',
    ModeOfDeliver: '',
    DespatchDetails: '',
    ModeOfTransportation: '',
    VehicleNumber: '',
    MarketVehicle: '',
    DieselRatePerLtrs: '',
    DieselConsumed: '',
    openingKMs: '',
    closingKMs: '',
    TotalKMs: '',
    TripMilage: '',
    VehicleCharges: '',
    LRCharges: '',
    GSTCharges: '',
    DoorDeliveryCharges: '',
    DoorPickUpCharges: '',
    LoadingCharges: '',
    UnLoadingCharges: '',
    DriverCharges: '',
    OtherExpenses: '',
    AdvanceReceived: '',
    Balance: '',
    OfficeCharge: '',
    DriverAdvance: '',
    TollCharges: '',
    BrokerCommission: '',
    HaltingCharges: '',
    TotalDieselAmount: '',
    CashGiven: '',
    TotalExpense: '',
    FinalAmount: '',
    TripAmount: '',
    BillingAmount: '',
    GrandTotal: '',
    Remarks: '',
    description: '',
  });
  const [showForm, setShowForm] = useState(false);
  const [showCNUpdate, setShowCNUpdate] = useState(false);
  const [showVehicleUpdate, setShowVehicleUpdate] = useState(false);
  const [showFinUpdate, setShowFinUpdate] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('############### UPDATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log(
        '@@@@@@@@@@@@@ INITIALIZE UPDATE BOOKING ###################'
      );
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');
    };
    initialize();
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitCNNumber = async (e) => {
    e.preventDefault();

    console.log('##################CN_number==>', cnNumber, '################');

    try {
      const response = await axios.get(
        `https://api.adiexpresslogistics.com/api/v1/trips?CN_number=${cnNumber}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        '################## DISPLAY Response ==>',
        response.data.data[0],
        '################'
      );
      const booking = response.data.data[0];
      setBookingData(booking);
      if (booking.CN_number === cnNumber) {
        setShowForm(true);
      } else {
        alert('An Invalid CN_number is entered, please check you CN_number');
      }
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while getting the booking for a CN_number');
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log('##################_ID==>', bookingData.id, '################');

    // // Remove empty key-value pairs from the form data
    // const filteredData = Object.entries(formData).reduce(
    //   (acc, [key, value]) => {
    //     if (value !== '') {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   },
    //   {}
    // );
    // Filter out empty values from formData
    let myArr = JSON.parse(JSON.stringify(formData));
    const filteredData = {};
    for (const key in myArr) {
      if (formData[key] !== '') {
        filteredData[key] = formData[key];
      }
    }
    console.log(
      '##################filteredData==>',
      filteredData,
      '################'
    );

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.patch(
        `https://api.adiexpresslogistics.com/api/v1/trips/${bookingData.id}`,
        filteredData,
        config
      );
      console.log('API response:', response.data);
      // Handle successful response here
      // Show success alert
      alert('Update Booking is successfully');
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while Updating the Booking ');
    }
  };
  const handleToggleCNForm = () => {
    setShowCNUpdate(!showCNUpdate);
    setShowVehicleUpdate(false);
    setShowFinUpdate(false);
  };
  const handleToggleVehicleForm = () => {
    setShowVehicleUpdate(!showVehicleUpdate);
    setShowCNUpdate(false);
    setShowFinUpdate(false);
  };
  const handleToggleFinForm = () => {
    setShowFinUpdate(!showFinUpdate);
    setShowVehicleUpdate(false);
    setShowCNUpdate(false);
  };

  return (
    <div>
      {!showForm ? (
        <div className="updateBookingCard">
          <h2>Enter CN_number to Update</h2>
          <form onSubmit={handleSubmitCNNumber}>
            <label>
              CN_number:
              <input
                type="text"
                name="CN_number"
                className="form-control form-control-lg"
                value={cnNumber}
                onChange={(e) => setCNNumber(e.target.value)}
              />
            </label>
            <button type="submit" className="btn-primary">
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div>
          <div className="bookCard-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item">
                <button
                  className="bookNav-link btn btn-primary"
                  onClick={handleToggleCNForm}
                >
                  Open CN Form
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="bookNav-link btn btn-primary"
                  onClick={handleToggleVehicleForm}
                >
                  Open Vehicle Form
                </button>
              </li>
              <li className="nav-item">
                <button
                  className="bookNav-link btn btn-primary"
                  onClick={handleToggleFinForm}
                >
                  Open Finance Form
                </button>
              </li>
            </ul>
          </div>
          {showCNUpdate && (
            <div className="updateBookingCard">
              <h2>Update CN Booking Details</h2>
              <form onSubmit={handleSubmitForm}>
                {/* Render the remaining form fields */}
                <label>
                  Consignor:
                  <input
                    type="text"
                    className="form-control"
                    name="Consignor"
                    value={formData.Consignor}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Consignee:
                  <input
                    type="text"
                    className="form-control"
                    name="Consignee"
                    value={formData.Consignee}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  BookingPoint:
                  <input
                    type="text"
                    className="form-control"
                    name="BookingPoint"
                    value={formData.BookingPoint}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DeliveryPoint:
                  <input
                    type="text"
                    className="form-control"
                    name="DeliveryPoint"
                    value={formData.DeliveryPoint}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  NumberOfBox:
                  <input
                    type="text"
                    className="form-control"
                    name="NumberOfBox"
                    value={formData.NumberOfBox}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  CN_Date:
                  <input
                    type="text"
                    className="form-control"
                    name="CN_Date"
                    value={formData.CN_Date}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  DeliveryDate:
                  <input
                    type="text"
                    className="form-control"
                    name="DeliveryDate"
                    value={formData.DeliveryDate}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Invoice_Number:
                  <input
                    type="text"
                    className="form-control"
                    name="Invoice_Number"
                    value={formData.Invoice_Number}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Invoice_Date:
                  <input
                    type="text"
                    className="form-control"
                    name="Invoice_Date"
                    value={formData.Invoice_Date}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  ETA:
                  <input
                    type="text"
                    className="form-control"
                    name="ETA"
                    value={formData.ETA}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  VehicleNumber:
                  <input
                    type="text"
                    className="form-control"
                    name="VehicleNumber"
                    value={formData.VehicleNumber}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  EWayBill:
                  <input
                    type="text"
                    className="form-control"
                    name="EWayBill"
                    value={formData.EWayBill}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Packing_Type:
                  <input
                    type="text"
                    className="form-control"
                    name="Packing_Type"
                    value={formData.Packing_Type}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Quantity:
                  <input
                    type="text"
                    className="form-control"
                    name="Quantity"
                    value={formData.Quantity}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Dimension:
                  <input
                    type="text"
                    className="form-control"
                    name="Dimension"
                    value={formData.Dimension}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Weight:
                  <input
                    type="text"
                    className="form-control"
                    name="Weight"
                    value={formData.Weight}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Part_Number:
                  <input
                    type="text"
                    className="form-control"
                    name="Part_Number"
                    value={formData.Part_Number}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  ItemDescription:
                  <input
                    type="text"
                    className="form-control"
                    name="ItemDescription"
                    value={formData.ItemDescription}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  POD_Upload_Date:
                  <input
                    type="text"
                    className="form-control"
                    name="POD_Upload_Date"
                    value={formData.POD_Upload_Date}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  CustomerCode:
                  <input
                    type="text"
                    className="form-control"
                    name="CustomerCode"
                    value={formData.CustomerCode}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DynamicQr:
                  <input
                    type="text"
                    className="form-control"
                    name="DynamicQr"
                    value={formData.DynamicQr}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  GSTIN:
                  <input
                    type="text"
                    className="form-control"
                    name="GSTIN"
                    value={formData.GSTIN}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  PAN:
                  <input
                    type="text"
                    className="form-control"
                    name="PAN"
                    value={formData.PAN}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  InsurancePolicyNum:
                  <input
                    type="text"
                    className="form-control"
                    name="InsurancePolicyNum"
                    value={formData.InsurancePolicyNum}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DeclaredInvoiceValue:
                  <input
                    type="text"
                    className="form-control"
                    name="DeclaredInvoiceValue"
                    value={formData.DeclaredInvoiceValue}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  SaidToContain:
                  <input
                    type="text"
                    className="form-control"
                    name="SaidToContain"
                    value={formData.SaidToContain}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DescriptionOfGoods:
                  <input
                    type="text"
                    className="form-control"
                    name="DescriptionOfGoods"
                    value={formData.DescriptionOfGoods}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  SpecialInstructions:
                  <input
                    type="text"
                    className="form-control"
                    name="SpecialInstructions"
                    value={formData.SpecialInstructions}
                    onChange={handleChange}
                  />
                </label>
                {/* Render the remaining form fields */}
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {/* */}
          {showVehicleUpdate && (
            <div className="updateBookingCard">
              <h2>Update Vehicle Booking Details</h2>
              <form onSubmit={handleSubmitForm}>
                {/* Render the remaining form fields */}
                <label>
                  VehicleNumber:
                  <input
                    type="text"
                    className="form-control"
                    name="VehicleNumber"
                    value={formData.VehicleNumber}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  MarketVehicle:
                  <input
                    type="text"
                    className="form-control"
                    name="MarketVehicle"
                    value={formData.MarketVehicle}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DieselRatePerLtrs:
                  <input
                    type="text"
                    className="form-control"
                    name="DieselRatePerLtrs"
                    value={formData.DieselRatePerLtrs}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DieselConsumed:
                  <input
                    type="text"
                    className="form-control"
                    name="DieselConsumed"
                    value={formData.DieselConsumed}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  openingKMs:
                  <input
                    type="text"
                    className="form-control"
                    name="openingKMs"
                    value={formData.openingKMs}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  closingKMs:
                  <input
                    type="text"
                    className="form-control"
                    name="closingKMs"
                    value={formData.closingKMs}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TotalKMs:
                  <input
                    type="text"
                    className="form-control"
                    name="TotalKMs"
                    value={formData.TotalKMs}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TripMilage:
                  <input
                    type="text"
                    className="form-control"
                    name="TripMilage"
                    value={formData.TripMilage}
                    onChange={handleChange}
                  />
                </label>
                {/* Render the remaining form fields */}
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
          {/* */}

          {showFinUpdate && (
            <div className="updateBookingCard">
              <h2>Update Finance related Details</h2>
              <form onSubmit={handleSubmitForm}>
                {/* Render the remaining form fields */}
                <label>
                  VehicleCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="VehicleCharges"
                    value={formData.VehicleCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  LRCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="LRCharges"
                    value={formData.LRCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  GSTCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="GSTCharges"
                    value={formData.GSTCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DoorDeliveryCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="DoorDeliveryCharges"
                    value={formData.DoorDeliveryCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DoorPickUpCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="DoorPickUpCharges"
                    value={formData.DoorPickUpCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  LoadingCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="LoadingCharges"
                    value={formData.LoadingCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  UnLoadingCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="UnLoadingCharges"
                    value={formData.UnLoadingCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DriverCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="DriverCharges"
                    value={formData.DriverCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  OtherExpenses:
                  <input
                    type="text"
                    className="form-control"
                    name="OtherExpenses"
                    value={formData.OtherExpenses}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  AdvanceReceived:
                  <input
                    type="text"
                    className="form-control"
                    name="AdvanceReceived"
                    value={formData.AdvanceReceived}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Balance:
                  <input
                    type="text"
                    className="form-control"
                    name="Balance"
                    value={formData.Balance}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  OfficeCharge:
                  <input
                    type="text"
                    className="form-control"
                    name="OfficeCharge"
                    value={formData.OfficeCharge}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  DriverAdvance:
                  <input
                    type="text"
                    className="form-control"
                    name="DriverAdvance"
                    value={formData.DriverAdvance}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TollCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="TollCharges"
                    value={formData.TollCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  BrokerCommission:
                  <input
                    type="text"
                    className="form-control"
                    name="BrokerCommission"
                    value={formData.BrokerCommission}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  HaltingCharges:
                  <input
                    type="text"
                    className="form-control"
                    name="HaltingCharges"
                    value={formData.HaltingCharges}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TotalDieselAmount:
                  <input
                    type="text"
                    className="form-control"
                    name="TotalDieselAmount"
                    value={formData.TotalDieselAmount}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  CashGiven:
                  <input
                    type="text"
                    className="form-control"
                    name="CashGiven"
                    value={formData.CashGiven}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TotalExpense:
                  <input
                    type="text"
                    className="form-control"
                    name="TotalExpense"
                    value={formData.TotalExpense}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  FinalAmount:
                  <input
                    type="text"
                    className="form-control"
                    name="FinalAmount"
                    value={formData.FinalAmount}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  TripAmount:
                  <input
                    type="text"
                    className="form-control"
                    name="TripAmount"
                    value={formData.TripAmount}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  BillingAmount:
                  <input
                    type="text"
                    className="form-control"
                    name="BillingAmount"
                    value={formData.BillingAmount}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  GrandTotal:
                  <input
                    type="text"
                    className="form-control"
                    name="GrandTotal"
                    value={formData.GrandTotal}
                    onChange={handleChange}
                  />
                </label>
                <label>
                  Remarks:
                  <input
                    type="text"
                    className="form-control"
                    name="Remarks"
                    value={formData.Remarks}
                    onChange={handleChange}
                  />
                </label>

                <label>
                  description:
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                  />
                </label>

                {/* Render the remaining form fields */}
                <button type="submit">Submit</button>
              </form>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

{
  /* <label>
              ModeOfDeliver:
              <input
                type="text"
                className="form-control"
                name="ModeOfDeliver"
                value={formData.ModeOfDeliver}
                onChange={handleChange}
              />
            </label> */
}
{
  /* <label>
               DespatchDetails:
              <input
                type="text"
                className="form-control"
                name="DespatchDetails"
                value={formData.DespatchDetails}
                onChange={handleChange}
              />
            </label> */
}
{
  /* <label>
              ModeOfTransportation:
              <input
                type="text"
                className="form-control"
                name="ModeOfTransportation"
                value={formData.ModeOfTransportation}
                onChange={handleChange}
              />
            </label> */
}

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const DeleteBooking = () => {
  const [cnNumber, setCNNumber] = useState('');
  // const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('############### CREATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log('@@@@@@@@@@@@@ INITIALIZE TO LOGIN ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');
    };
    initialize();
  }, []);

  const handleCNNumberChange = (event) => {
    setCNNumber(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('################## CALLING DISPLAY ################');
    console.log('##################CN_number==>', cnNumber, '################');
    try {
      const response = await axios.get(
        `https://api.adiexpresslogistics.com/api/v1/trips?CN_number=${cnNumber}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        '################## DELETE LEVEL-0 Response ==>',
        response.data.data[0],
        '################'
      );
      const booking = response.data.data[0];
      alert('Are you sure you want to Delete Booking with this CN_number');
      if (booking.CN_number === cnNumber) {
        try {
          const response = await axios.delete(
            `https://api.adiexpresslogistics.com/api/v1/trips/${booking.id}`,

            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          console.log(
            '################## DELETE LEVEL-1 Response ==>',
            response.status,
            '################'
          );

          if (response.status === 204) {
            alert(`Booking is deleted with  CN_number= ${cnNumber} `);
          }
        } catch (error) {
          console.error(error);
          console.log('Error-->level-1', error); // Error message
          // Handle the error
          if (error.response) {
            // The request was made and the server responded with a status code outside the range of 2xx
            console.log(error.response.data); // Error response data
            console.log(error.response.status); // Error status code
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request); // Error request
          } else {
            // Something happened in setting up the request that triggered an error
            console.log('Error', error.message); // Error message
          }
          console.log(error.config); // Axios request config
          // console.log('Error', error.message); // Error message
          // Show error alert
          alert('An error occurred while Deleting the booking Level-0');
        }
      } else {
        alert('An Invalid CN_number is entered, please check you CN_number');
      }
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config

      // Show error alert
      alert(
        'An error occurred while Deleting the booking Level-0, Please check you CN_number'
      );
    }
  };

  return (
    <div className="grid-container">
      <div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/air-cargo-100.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/air-cargo-200.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
      </div>
      <div>
        <div className="bookCard">
          <h2>Enter CN_number</h2>
          <form onSubmit={handleSubmit}>
            <input
              id="form2Example17"
              className="form-control form-control-lg"
              type="Number"
              value={cnNumber}
              onChange={handleCNNumberChange}
            />

            <button className="btn btn-primary" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const ALLBookings = () => {
  const [cnNumber, setCNNumber] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showCNUpdate, setShowCNUpdate] = useState(false);
  const [showVehicleUpdate, setShowVehicleUpdate] = useState(false);
  const [showFinUpdate, setShowFinUpdate] = useState(false);

  console.log('############### CREATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log('@@@@@@@@@@@@@ INITIALIZE TO LOGIN ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');

      console.log('################## CALLING DISPLAY ################');
      console.log(
        '##################CN_number==>',
        cnNumber,
        '################'
      );
      try {
        const response = await axios.get(
          `https://api.adiexpresslogistics.com/api/v1/trips`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(
          '################## DISPLAY Response-level0 ==>',
          response.data,
          '################'
        );
        const booking = response.data.data;
        setBookingData(booking);
        console.log(
          '################## DISPLAY Response-level-1 ==>',
          response.data.data,
          '################'
        );
      } catch (error) {
        console.error(error);
        console.log('Error', error); // Error message
        // Handle the error
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.log(error.response.data); // Error response data
          console.log(error.response.status); // Error status code
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request); // Error request
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error', error.message); // Error message
        }
        console.log(error.config); // Axios request config
        // console.log('Error', error.message); // Error message
        // Show error alert
        alert('An error occurred while Displaying the booking');
      }
    };
    initialize();
  }, []);

  const handleCNNumberChange = (event) => {
    setCNNumber(event.target.value);
  };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   console.log('################## CALLING DISPLAY ################');
  //   console.log('##################CN_number==>', cnNumber, '################');
  //   try {
  //     const response = await axios.get(
  //       `https://api.adiexpresslogistics.com/api/v1/trips`,

  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     console.log(
  //       '################## DISPLAY Response ==>',
  //       response.data,
  //       '################'
  //     );
  //     const booking = response.data;
  //     setBookingData(booking);
  //     console.log(
  //       '################## DISPLAY Response ==>',
  //       response.data.data,
  //       '################'
  //     );
  //   } catch (error) {
  //     console.error(error);
  //     console.log('Error', error); // Error message
  //     // Handle the error
  //     if (error.response) {
  //       // The request was made and the server responded with a status code outside the range of 2xx
  //       console.log(error.response.data); // Error response data
  //       console.log(error.response.status); // Error status code
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.log(error.request); // Error request
  //     } else {
  //       // Something happened in setting up the request that triggered an error
  //       console.log('Error', error.message); // Error message
  //     }
  //     console.log(error.config); // Axios request config
  //     // console.log('Error', error.message); // Error message
  //     // Show error alert
  //     alert('An error occurred while Displaying the booking');
  //   }
  // };

  // const handleToggleCNForm = () => {
  //   setShowCNUpdate(!showCNUpdate);
  //   setShowVehicleUpdate(false);
  //   setShowFinUpdate(false);
  // };
  // const handleToggleVehicleForm = () => {
  //   setShowVehicleUpdate(!showVehicleUpdate);
  //   setShowCNUpdate(false);
  //   setShowFinUpdate(false);
  // };
  // const handleToggleFinForm = () => {
  //   setShowFinUpdate(!showFinUpdate);
  //   setShowVehicleUpdate(false);
  //   setShowCNUpdate(false);
  // };
  let trips = [];
  trips = JSON.parse(JSON.stringify(bookingData));

  console.log('#############===>>>>', trips);
  return (
    //   <div>
    //     <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
    //       <img
    //         src="assets/img/login-cargo-100.jpg"
    //         alt="login form"
    //         className="img-fluid"
    //         style={{ borderRadius: '1rem 0 0 1rem' }}
    //       />
    //     </div>
    //     <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
    //       <img
    //         src="assets/img/login-cargo-200.jpg"
    //         alt="login form"
    //         className="img-fluid"
    //         style={{ borderRadius: '1rem 0 0 1rem' }}
    //       />
    //     </div>
    //   </div>
    //   <div>

    //       <div>
    //         <div className="bookCard-header">
    //           <ul className="nav nav-tabs card-header-tabs">
    //             <li className="nav-item">
    //               <button
    //                 className="bookNav-link btn btn-primary"
    //                 onClick={handleToggleCNForm}
    //               >
    //                 Open CN Form
    //               </button>
    //             </li>
    //             <li className="nav-item">
    //               <button
    //                 className="bookNav-link btn btn-primary"
    //                 onClick={handleToggleVehicleForm}
    //               >
    //                 Open Vehicle Form
    //               </button>
    //             </li>
    //             <li className="nav-item">
    //               <button
    //                 className="bookNav-link btn btn-primary"
    //                 onClick={handleToggleFinForm}
    //               >
    //                 Open Finance Form
    //               </button>
    //             </li>
    //           </ul>
    //         </div>
    //         {showCNUpdate && (
    //           <div className="bookCard">
    //             <h2>Booking Details</h2>
    //             <table>
    //               <tbody>
    //                 <tr>
    //                   <td>Consignor:</td>
    //                   <td>{bookingData.Consignor.CompanyName}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Consignee:</td>
    //                   <td>{bookingData.Consignee.CompanyName}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Booking Point:</td>
    //                   <td>{bookingData.BookingPoint}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Delivery Point:</td>
    //                   <td>{bookingData.DeliveryPoint}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Number of Boxes:</td>
    //                   <td>{bookingData.NumberOfBox}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>CN_number:</td>
    //                   <td>{bookingData.CN_number}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TripStatus:</td>
    //                   <td>{bookingData.TripStatus}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>CNStatus:</td>
    //                   <td>{bookingData.CNStatus}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>CN_Date:</td>
    //                   <td>{bookingData.CN_Date}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>BookingDate:</td>
    //                   <td>{bookingData.BookingDate}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>DeliveryDate:</td>
    //                   <td>{bookingData.DeliveryDate}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Invoice_Number:</td>
    //                   <td>{bookingData.Invoice_Number}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Invoice_Date:</td>
    //                   <td>{bookingData.Invoice_Date}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> ETA:</td>
    //                   <td>{bookingData.ETA}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> EWayBill:</td>
    //                   <td>{bookingData.EWayBill}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Packing_Type:</td>
    //                   <td>{bookingData.Packing_Type}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>Quantity:</td>
    //                   <td>{bookingData.Quantity}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Dimension:</td>
    //                   <td>{bookingData.Dimension}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Weight:</td>
    //                   <td>{bookingData.Weight}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Part_Number:</td>
    //                   <td>{bookingData.Part_Number}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> ItemDescription:</td>
    //                   <td>{bookingData.ItemDescription}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> POD_Upload_Date:</td>
    //                   <td>{bookingData.POD_Upload_Date}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> CustomerCode:</td>
    //                   <td>{bookingData.CustomerCode}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> DynamicQr:</td>
    //                   <td>{bookingData.DynamicQr}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> GSTIN:</td>
    //                   <td>{bookingData.GSTIN}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> PAN:</td>
    //                   <td>{bookingData.PAN}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> InsurancePolicyNum:</td>
    //                   <td>{bookingData.InsurancePolicyNum}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> DeclaredInvoiceValue:</td>
    //                   <td>{bookingData.DeclaredInvoiceValue}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> SaidToContain:</td>
    //                   <td>{bookingData.SaidToContain}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> DescriptionOfGoods:</td>
    //                   <td>{bookingData.DescriptionOfGoods}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> SpecialInstructions:</td>
    //                   <td>{bookingData.SpecialInstructions}</td>
    //                 </tr>
    //                 {/* <tr>
    //               <td> ModeOfDeliver:</td>
    //               <td>{bookingData.ModeOfDeliver}</td>
    //             </tr>
    //             <tr>
    //               <td> DespatchDetails:</td>
    //               <td>{bookingData.DespatchDetails}</td>
    //             </tr>
    //             <tr>
    //               <td> ModeOfTransportation:</td>
    //               <td>{bookingData.ModeOfTransportation}</td>
    //             </tr> */}
    //               </tbody>
    //             </table>
    //           </div>
    //         )}
    //         {showVehicleUpdate && (
    //           <div className="bookCard">
    //             <h2>Booking Details</h2>
    //             <table>
    //               <tbody>
    //                 <tr>
    //                   <td>VehicleNumber:</td>
    //                   <td>{bookingData.VehicleNumber}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>MarketVehicle:</td>
    //                   <td>{bookingData.MarketVehicle}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>DieselRate Per Litre:</td>
    //                   <td>{bookingData.DieselRatePerLtrs}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>DieselConsumed:</td>
    //                   <td>{bookingData.DieselConsumed}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>openingKMs:</td>
    //                   <td>{bookingData.openingKMs}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>closingKMs:</td>
    //                   <td>{bookingData.closingKMs}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TotalKMs:</td>
    //                   <td>{bookingData.TotalKMs}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TripMilage:</td>
    //                   <td>{bookingData.TripMilage}</td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //           </div>
    //         )}
    //         {showFinUpdate && (
    //           <div className="bookCard">
    //             <h2>Booking Details</h2>
    //             <table>
    //               <tbody>
    //                 <tr>
    //                   <td>VehicleCharges:</td>
    //                   <td>{bookingData.VehicleCharges}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>LRCharges:</td>
    //                   <td>{bookingData.LRCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>GSTCharges:</td>
    //                   <td>{bookingData.GSTCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> DoorDeliveryCharges:</td>
    //                   <td>{bookingData.DoorDeliveryCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>DoorPickUpCharges:</td>
    //                   <td>{bookingData.DoorPickUpCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>LoadingCharges:</td>
    //                   <td>{bookingData.LoadingCharges}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>UnLoadingCharges:</td>
    //                   <td>{bookingData.UnLoadingCharges}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>DriverCharges:</td>
    //                   <td>{bookingData.DriverCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>OtherExpenses:</td>
    //                   <td>{bookingData.OtherExpenses}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> AdvanceReceived:</td>
    //                   <td>{bookingData.AdvanceReceived}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Balance:</td>
    //                   <td>{bookingData.Balance}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>OfficeCharge:</td>
    //                   <td>{bookingData.OfficeCharge}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>DriverAdvance:</td>
    //                   <td>{bookingData.DriverAdvance}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TollCharges:</td>
    //                   <td>{bookingData.TollCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>BrokerCommission:</td>
    //                   <td>{bookingData.BrokerCommission}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>HaltingCharges:</td>
    //                   <td>{bookingData.HaltingCharges}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TotalDieselAmount:</td>
    //                   <td>{bookingData.TotalDieselAmount}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>CashGiven:</td>
    //                   <td>{bookingData.CashGiven}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>TotalExpense:</td>
    //                   <td>{bookingData.TotalExpense}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>FinalAmount:</td>
    //                   <td>{bookingData.FinalAmount}</td>
    //                 </tr>

    //                 <tr>
    //                   <td>TripAmount:</td>
    //                   <td>{bookingData.TripAmount}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>BillingAmount:</td>
    //                   <td>{bookingData.BillingAmount}</td>
    //                 </tr>
    //                 <tr>
    //                   <td> GrandTotal:</td>
    //                   <td>{bookingData.GrandTotal}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>Remarks:</td>
    //                   <td>{bookingData.Remarks}</td>
    //                 </tr>
    //                 <tr>
    //                   <td>description:</td>
    //                   <td>{bookingData.description}</td>
    //                 </tr>
    //               </tbody>
    //             </table>
    //           </div>
    //         )}
    //       </div>

    //   </div>
    // <div className="grid-container">
    <div>
      <div>
        {' '}
        <h5>ALLBookings</h5>
      </div>
      <div>
        <table className="bookingTable">
          <thead>
            <tr>
              <th>Booking</th>
              <th>Consignor</th>
              <th>Consignee</th>
              <th>CN_number</th>
              <th>CNStatus</th>
              <th>TripStatus</th>
              <th>BookingPoint</th>
              <th>DeliveryPoint</th>
              <th>CN_Date</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(trips) &&
              trips.map((item, index) => (
                <tr key={item?.CN_number}>
                  <td>{index + 1}</td>
                  <td>{item?.Consignor.CompanyName}</td>
                  <td>{item?.Consignee.CompanyName}</td>
                  <td>{item?.CN_number}</td>
                  <td>{item?.CNStatus}</td>
                  <td>{item?.TripStatus}</td>
                  <td>{item?.BookingPoint}</td>
                  <td>{item?.DeliveryPoint}</td>
                  {/* <td>{item?.CN_Date}</td> */}
                  <td>
                    {item?.CN_Date
                      ? new Date(item.CN_Date).toLocaleDateString('en-GB')
                      : ''}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const UpdateStatus = () => {
  const [cnNumber, setCNNumber] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  console.log('############### UPDATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log(
        '@@@@@@@@@@@@@ INITIALIZE UPDATE BOOKING ###################'
      );
     
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');
    };
    initialize();
  }, []);
  const handleSubmitCNNumber = async (e) => {
    e.preventDefault();

    console.log('##################CN_number==>', cnNumber, '################');

    try {
      const response = await axios.get(
        `https://api.adiexpresslogistics.com/api/v1/trips?CN_number=${cnNumber}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(
        '################## DISPLAY Response ==>',
        response.data.data[0],
        '################'
      );
      const booking = response.data.data[0];
      setBookingData(booking);
      if (booking.CN_number === cnNumber) {
        setShowForm(true);
      } else {
        alert('An Invalid CN_number is entered, please check you CN_number');
      }
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while getting the booking for a CN_number');
    }
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    console.log('##################_ID==>', bookingData.id, '################');

    // // Remove empty key-value pairs from the form data
    const payload = {
      TripStatus: selectedStatus,
    };
    // const filteredData = Object.entries(formData).reduce(
    //   (acc, [key, value]) => {
    //     if (value !== '') {
    //       acc[key] = value;
    //     }
    //     return acc;
    //   },
    //   {}
    // );
    // Filter out empty values from formData
    let myArr = JSON.parse(JSON.stringify(payload));
    const filteredData = {};
    for (const key in myArr) {
      if (payload[key] !== '') {
        filteredData[key] = payload[key];
      }
    }
    console.log(
      '##################filteredData==>',
      filteredData,
      '################'
    );

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.patch(
        `https://api.adiexpresslogistics.com/api/v1/trips/${bookingData.id}`,
        filteredData,
        config
      );
      console.log('API response:', response.data);
      // Handle successful response here
      // Show success alert
      alert('Update Booking Status is successfully');
    } catch (error) {
      console.error(error);
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      // Show error alert
      alert('An error occurred while Updating the Booking ');
    }
  };
  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();

  //   const payload = {
  //     TripStatus: selectedStatus,
  //   };

  //   try {
  //     const response = await axios.patch(
  //       `https://api.adhiexpresslogistics.com/api/vi/trips/${cnNumber}`,
  //       payload,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     // Handle the response accordingly
  //     // ...
  //   } catch (error) {
  //     // Handle the error
  //     // ...
  //   }
  // };
  

  return (
    <div className="grid-container">
     <div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/login-cargo-100.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
        <div className="col-md-6 bookCol-lg-5 d-none d-md-block">
          <img
            src="assets/img/login-cargo-200.jpg"
            alt="login form"
            className="img-fluid"
            style={{ borderRadius: '1rem 0 0 1rem' }}
          />
        </div>
      </div>
    <div>
      {!showForm ? (
        <div className="bookCard">
          <h2>Enter CN_number to Update</h2>
          <form onSubmit={handleSubmitCNNumber}>
            <label>
            </label>
              CN_number:
              <input
                type="text"
                name="CN_number"
                className="form-control form-control-lg"
                value={cnNumber}
                onChange={(e) => setCNNumber(e.target.value)}
              />
            <button type="submit" className="btn-primary">
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="bookCard">
      
        <form onSubmit={handleSubmitForm}>
          <select
          className="btn btn-primary"
            value={selectedStatus}
            onChange={(e) => setSelectedStatus(e.target.value)}
          >
            <option value="">Select CNStatus</option>
            <option value="Booking">Booking</option>
            <option value="Branch-Dispatch">Branch-Dispatch</option>
            <option value="Hub-Center-Start">Hub-Center-Start</option>
            <option value="In-Transit">In-Transit</option>
            <option value="Halted">Halted</option>
            <option value="Hub-Center-End">Hub-Center-End</option>
            <option value="Out-for-Delivery">Out-for-Delivery</option>
            <option value="Delivered">Delivered</option>
            <option value="Close">Close</option>
          </select>

          <button className="btn btn-primary" type="submit" disabled={!selectedStatus}>
            Update CNStatus
          </button>
        </form>
        </div>
       
      )}
    </div>
    </div>
  );
};

//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */
//************************************************************************************************************************************* */

export const MyBookings = () => {
  const [cnNumber, setCNNumber] = useState([]);
  const [bookingData, setBookingData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showCNUpdate, setShowCNUpdate] = useState(false);
  const [showVehicleUpdate, setShowVehicleUpdate] = useState(false);
  const [showFinUpdate, setShowFinUpdate] = useState(false);

  console.log('############### CREATING BOOKING ==> ###############');

  useEffect(() => {
    const initialize = async () => {
      console.log('@@@@@@@@@@@@@ INITIALIZE TO MY BOOKINGS ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const userIdRedux = state.auth.userId;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setUserId(userIdRedux);
      setToken(tokenRedux);
      setLoading(loading);

      console.log('@@@@@@@@@@@@@ ROLE', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@ ID', userIdRedux, '###################');
      console.log('@@@@@@@@@@@@@ TOKEN', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');

      console.log('################## CALLING DISPLAY ################');
      console.log(
        '##################USER ID==>',
        userIdRedux,
        '################'
      );
      try {
        const response = await axios.get(
          `https://api.adiexpresslogistics.com/api/v1/trips?Operator=${userIdRedux}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(
          '################## DISPLAY Response-level0 ==>',
          response.data,
          '################'
        );
        const booking = response.data.data;
        setBookingData(booking);
        console.log(
          '################## DISPLAY Response-level-1 ==>',
          response.data.data,
          '################'
        );
      } catch (error) {
        console.error(error);
        console.log('Error', error); // Error message
        // Handle the error
        if (error.response) {
          // The request was made and the server responded with a status code outside the range of 2xx
          console.log(error.response.data); // Error response data
          console.log(error.response.status); // Error status code
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request); // Error request
        } else {
          // Something happened in setting up the request that triggered an error
          console.log('Error', error.message); // Error message
        }
        console.log(error.config); // Axios request config
        // console.log('Error', error.message); // Error message
        // Show error alert
        alert('An error occurred while Displaying the booking');
      }
    };
    initialize();
  }, []);

  const handleCNNumberChange = (event) => {
    setCNNumber(event.target.value);
  };

 
  let trips = [];
  trips = JSON.parse(JSON.stringify(bookingData));

  console.log('#############===>>>>', trips);
  return (
    
    <div>
      <div>
        {' '}
        <h5>ALLBookings</h5>
      </div>
      <div>
        <table className="bookingTable">
          <thead>
            <tr>
              <th>Booking</th>
              <th>Consignor</th>
              <th>Consignee</th>
              <th>CN_number</th>
              <th>CNStatus</th>
              <th>TripStatus</th>
              <th>BookingPoint</th>
              <th>DeliveryPoint</th>
              <th>CN_Date</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(trips) &&
              trips.map((item, index) => (
                <tr key={item?.CN_number}>
                  <td>{index + 1}</td>
                  <td>{item?.Consignor.CompanyName}</td>
                  <td>{item?.Consignee.CompanyName}</td>
                  <td>{item?.CN_number}</td>
                  <td>{item?.CNStatus}</td>
                  <td>{item?.TripStatus}</td>
                  <td>{item?.BookingPoint}</td>
                  <td>{item?.DeliveryPoint}</td>
                  {/* <td>{item?.CN_Date}</td> */}
                  <td>
                    {item?.CN_Date
                      ? new Date(item.CN_Date).toLocaleDateString('en-GB')
                      : ''}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
