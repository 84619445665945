import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import CryptoJS from 'crypto-js'
// Get the values from the Redux store
// const userRoleRedux = useSelector((state) => state.auth.userRole);
// const tokenRedux = useSelector((state) => state.auth.token);
// const isLoadingRedux = useSelector((state) => state.auth.isLoading);

const initialState =  {
    userId: '63fc7881e8a06cb75da92909',
    userRole: 'user',
    token: 'defaultToken',
    isLoading: true,
    // isLoading: localStorage.getItem('isLoading') ? false :true,
};


const secureLocalStorage = {
  getItem: (key) => {
    const decryptedValue = localStorage.getItem(key);
    if (decryptedValue) {
      // Decrypt the value
      // Perform decryption using a secure algorithm (e.g., AES encryption) and secret key
      const decryptedBytes = CryptoJS.AES.decrypt(decryptedValue, key);
      const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
      console.log("1. getItem enc===>>",decryptedValue)
      console.log("2. getItem dec===>>",decryptedData)
      return decryptedData;
    }
    return null;
  },
  setItem: (key, encryptedValue) => {
    // Encrypt the value
    // Perform encryption using a secure algorithm (e.g., AES encryption) and secret key
    const encryptedData = CryptoJS.AES.encrypt(encryptedValue, key).toString();
    // return encryptedData;
    console.log("1.setItem dec===>>",encryptedValue)
    console.log("2.setItem enc===>>",encryptedData)
    localStorage.setItem(key, encryptedData);
  },
};



const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserRole: (state, action) => {
        console.log("$$$$$$$$$$$$$$$$$ SET ROLE",action,"$$$$$$$$$$$$$$$")
        // if(state.isLoading=== false){
        state.userRole = action.payload;
        // localStorage.setItem('role', JSON.stringify(action.payload))
        secureLocalStorage.setItem('role', action.payload);
        // }
        console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ",state.userRole,"$$$$$$$$$$$$$$$")
    },
    setUserId: (state, action) => {
      console.log("$$$$$$$$$$$$$$$$$ SET ID",action,"$$$$$$$$$$$$$$$")
      // if(state.isLoading=== false){
      state.userId = action.payload;
      // localStorage.setItem('role', JSON.stringify(action.payload))
      secureLocalStorage.setItem('userId', action.payload);
      // }
      console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ",state.userRole,"$$$$$$$$$$$$$$$")
  },
    setToken: (state, action) => {
        console.log("$$$$$$$$$$$$$$$$$ SET TOKEN",action,"$$$$$$$$$$$$$$$")
        // if(state.isLoading=== false){

          state.token = action.payload;
          // localStorage.setItem('token', JSON.stringify(action.payload))
          secureLocalStorage.setItem('token', action.payload);
        // }
        console.log("$$$$$$$$$$$$$$$$$ CURRENT TOKEN",state.token,"$$$$$$$$$$$$$$$")
    },
    setLoading: (state, action) => {
      console.log("$$$$$$$$$$$$$$$$$ SET LOADING",action,"$$$$$$$$$$$$$$$")
     
      state.isLoading = action.payload;
      // localStorage.setItem('isLoading', JSON.stringify(action.payload))
      localStorage.setItem('isLoading', action.payload);
      console.log("$$$$$$$$$$$$$$$$$ CURRENT LOADING",state.isLoading,"$$$$$$$$$$$$$$$")
    },
    initializeAuth: (state, action) => {
      // Handle the initialization logic here
      // You can access the payload using action.payload
      // Modify the state accordingly
      // For example:
      // state.userRole = action.payload.userRole;
      // state.token = action.payload.token;
      // state.isLoading = action.payload.isLoading;
      const userRoleRedux = action.payload.userRole;
      const userIdRedux = action.payload.userId;
      const tokenRedux = action.payload.token;
      const isLoadingRedux = action.payload.isLoading;

      const userRoleLocalStorage = secureLocalStorage.getItem('role');
      const userIdLocalStorage = secureLocalStorage.getItem('userId');
      const tokenLocalStorage = secureLocalStorage.getItem('token');
      const isLoadingLocalStorage = localStorage.getItem('isLoading');
      

      console.log("$$$$$$$$$$$$$$$$$ CURRENT DATA ===>",userIdLocalStorage, userRoleLocalStorage,tokenLocalStorage,isLoadingLocalStorage,"$$$$$$$$$$$$$$$")

      console.log("$$$$$$$$$$$$$$$$$ CURRENT LOADING DATA $$$$$$$$$$$$$$$")
      state.userRole = userRoleLocalStorage?? userRoleRedux ;
      state.userId = userIdLocalStorage?? userIdRedux ;
      state.token = tokenLocalStorage ?? tokenRedux ;
      state.isLoading = isLoadingLocalStorage ?? isLoadingRedux;

      console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ==>",state.userRole,"$$$$$$$$$$$$$$$");
      console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ==>",state.userId,"$$$$$$$$$$$$$$$");
      console.log("$$$$$$$$$$$$$$$$$ CURRENT TOKEN==>",state.token,"$$$$$$$$$$$$$$$");
      console.log("$$$$$$$$$$$$$$$$$ CURRENT LOADING==>",state.isLoading,"$$$$$$$$$$$$$$$");
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase('auth/initialize', (state, action) => {
  //     const userRoleRedux = action.payload.userRole;
  //     const tokenRedux = action.payload.token;
  //     const isLoadingRedux = action.payload.isLoading;

  //     const userRoleLocalStorage = secureLocalStorage.getItem('role');
  //     const tokenLocalStorage = secureLocalStorage.getItem('token');
  //     const isLoadingLocalStorage = localStorage.getItem('isLoading');
      

  //     console.log("$$$$$$$$$$$$$$$$$ CURRENT DATA ===>", userRoleLocalStorage,tokenLocalStorage,isLoadingLocalStorage,"$$$$$$$$$$$$$$$")

  //     console.log("$$$$$$$$$$$$$$$$$ CURRENT LOADING DATA $$$$$$$$$$$$$$$")
  //     state.userRole = userRoleLocalStorage?? userRoleRedux ;
  //     state.token = tokenLocalStorage ?? tokenRedux ;
  //     state.isLoading = isLoadingLocalStorage ?? isLoadingRedux;

  //     console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ==>",state.userRole,"$$$$$$$$$$$$$$$");
  //     console.log("$$$$$$$$$$$$$$$$$ CURRENT TOKEN==>",state.token,"$$$$$$$$$$$$$$$");
  //     console.log("$$$$$$$$$$$$$$$$$ CURRENT LOADING==>",state.isLoading,"$$$$$$$$$$$$$$$");
  //   });
  // },
});

export const { setUserRole,setUserId, setToken ,setLoading,initializeAuth } = authSlice.actions;

export default authSlice.reducer;


// import { createSlice } from '@reduxjs/toolkit';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const initialState = {
//   userRole: 'defaultUserRole',
//   token: 'defaultToken',
// };

// const authSlice = createSlice({
//   name: 'auth',
//   initialState,
//   reducers: {
//     setUserRole: (state, action) => {
//       console.log("$$$$$$$$$$$$$$$$$ SET ROLE", action, "$$$$$$$$$$$$$$$");
//       state.userRole = action.payload;
//       console.log("$$$$$$$$$$$$$$$$$ CURRENT ROLE ", state.userRole, "$$$$$$$$$$$$$$$");
//     },
//     setToken: (state, action) => {
//       console.log("$$$$$$$$$$$$$$$$$ SET TOKEN", action, "$$$$$$$$$$$$$$$");
//       state.token = action.payload;
//       console.log("$$$$$$$$$$$$$$$$$ CURRENT TOKEN", state.token, "$$$$$$$$$$$$$$$");
//     },
//   },
// });

// const persistConfig = {
//   key: 'auth',
//   storage,
// };

// const persistedAuthSlice = persistReducer(persistConfig, authSlice.reducer);

// export const { setUserRole, setToken } = persistedAuthSlice.actions;

// export default persistedAuthSlice;

