import React, { useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserRole,setUserId, setToken,setLoading } from '../store/authSlice';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  localStorage.clear();
  
  const handleLogin = async () => {
    try {
      // Retrieve the email and password values from the input fields
      const Email = document.getElementById('form2Example17').value;
      const Password = document.getElementById('form2Example27').value;
      // Make an Axios POST request to your login endpoint
      console.log('@@@@@@@@@@@@@', email, password, '$$$$$$$$$$$$$$$$$$$');

      const response = await axios.post(
        'https://api.adiexpresslogistics.com/api/v1/users/login',
       
        {
          email: Email,
          password: Password,
        },
        {
          // headers:{"Accept":"application/json, text/plain, /","Content-Type": "multipart/form-data"}
          // headers:{"Accept":"application/json, text/plain, /","Content-Type": "application/json"}
        }
      );

      console.log('@#$%@#$%@#$%@#$%====>>>>', response.data);
      // If the login is successful, you can store the token in local storage or a state management solution
      const token = response.data.token;
      const role = response.data.data.user.role;
      const userId = response.data.data.user.id;
      console.log('@$$$@$@$@$@$@$@$@', token, '#@#@#@#@#@#@#@#@#@#@#@');
      console.log('@$$$@$@$@$@$@$@$@', role, '#@#@#@#@#@#@#@#@#@#@#@');
      console.log('@$$$@$@$@$@$@$@$@', userId, '#@#@#@#@#@#@#@#@#@#@#@');
      dispatch(setLoading(false));
      dispatch(setUserRole(response.data.data.user.role));
      dispatch(setUserId(response.data.data.user.id));
      dispatch(setToken(response.data.token));

      // Set the token as an HTTP-only cookie with the Secure flag
      document.cookie = `token=${token}; path=/; Secure; HttpOnly`;
      // response.cookie('jwt', refreshToken, { httpOnly: true, maxAge: 24 * 60 * 60 * 1000 });

      // Open the Dashboard component or navigate to the dashboard route
      // You can use React Router for navigation if you're using it in your project
      // Here, I'm assuming you have a separate Dashboard component

       window.location.href = '/dashboard'; // Replace '/dashboard' with the appropriate route for your Dashboard component
    } catch (error) {
      // Handle any errors that occur during the login process
      // console.log(error);
      // console.log(error.response.data)
      // console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
      console.log('Error', error); // Error message
      // Handle the error
      if (error.response) {
        // The request was made and the server responded with a status code outside the range of 2xx
        console.log(error.response.data); // Error response data
        console.log(error.response.status); // Error status code
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request); // Error request
      } else {
        // Something happened in setting up the request that triggered an error
        console.log('Error', error.message); // Error message
      }
      console.log(error.config); // Axios request config
      // console.log('Error', error.message); // Error message
    }
  };

  // const handleUserRoleChange = () => {
  //   dispatch(setUserRole('admin')); // Set the user role to 'admin'
  // };

  // const handleTokenChange = () => {
  //   dispatch(setToken('your_token_here')); // Set the token to your desired value
  // };

  return (
    <section className="vh-100" style={{ backgroundColor: '#0e1d34' }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col col-xl-10">
            <div className="card" style={{ borderRadius: '1rem' }}>
              <div className="row g-0">
                <div className="col-md-6 col-lg-5 d-none d-md-block">
                  <img
                    src="assets/img/logincargo.jpg"
                  
                    alt="login form"
                    className="img-fluid"
                    style={{ borderRadius: '1rem 0 0 1rem' }}
                  />
                </div>
                <div className="col-md-6 col-lg-7 d-flex align-items-center">
                  <div className="card-body p-4 p-lg-5 text-black">
                    <form>
                     
                      <div className="header  container-fluid container-xl d-flex align-items-center justify-content-between fixed-top">
                        <a href="/" className="logo d-flex align-items-center">
                          {/* Uncomment the line below if you also wish to use an image logo */}
                          <img
                            src={
                              process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'
                            }
                            alt=""
                          />
                        </a>
                      </div>
                      <h5
                        className="fw-normal mb-3 pb-3"
                        style={{ letterSpacing: 1 }}
                      >
                        Sign into your account
                      </h5>
                      <div className="form-outline mb-4">
                        <input
                          type="email"
                          id="form2Example17"
                          className="form-control form-control-lg"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form2Example17">
                          Email address
                        </label>
                      </div>
                      <div className="form-outline mb-4">
                        <input
                          type="password"
                          id="form2Example27"
                          className="form-control form-control-lg"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="form-label" htmlFor="form2Example27">
                          Password
                        </label>
                      </div>
                      <div className="pt-1 mb-4">
                        <button
                          className="btn btn-dark btn-lg btn-block"
                          type="button"
                          onClick={handleLogin}
                        >
                          Login
                        </button>
                      </div>
                      <a className="small text-muted" href="#!">
                        Forgot password?
                      </a>
                      <p className="mb-5 pb-lg-2" style={{ color: '#393f81' }}>
                        Don't have an account?{' '}
                        <a href="#!" style={{ color: '#393f81' }}>
                          Register here
                        </a>
                      </p>
                      <a href="#!" className="small text-muted">
                        Terms of use.
                      </a>
                      <a href="#!" className="small text-muted">
                        Privacy policy
                      </a>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
