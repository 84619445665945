import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import Contact from './pages/Contact';
import ServiceDetails from './pages/ServiceDetails';
import Shipment from './pages/Shipment';
import GetAQuotes from './pages/GetAQuotes';
import Login from './pages/Login';
import DashBoard from './pages/DashBoard';

import { useEffect } from 'react';

function App() {
  // const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // useEffect(() => {
  //   if (action !== 'POP') {
  //     window.scrollTo(0, 0);
  //   }
  // }, [action]);

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    //TODO: Update meta titles and descriptions below
    switch (pathname) {
      case '/':
        title = 'Home Page';
        metaDescription = '';
        break;
      case '/Services':
        title = 'Services';
        metaDescription = '';
        break;
      case '/About':
        title = 'AboutUs';
        metaDescription = '';
        break;
      case '/Pricing':
        title = 'Pricing';
        metaDescription = '';
        break;
      case '/ServiceDetails':
        title = 'ServiceDetails';
        metaDescription = '';
        break;
      case '/Shipment':
        title = 'Shipment';
        metaDescription = '';
        break;
      case '/Contact':
        title = 'Contact';
        metaDescription = '';
        break;
      case '/GetAQuotes':
        title = 'GetAQuotes';
        metaDescription = '';
        break;
      case '/Login':
        title = 'Login';
        metaDescription = '';
        break;
      case '/DashBoard':
        title = 'DashBoard';
        metaDescription = '';
        break;
      default:
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/About" element={<About />} />
      <Route path="/Services" element={<Services />} />
      <Route path="/Pricing" element={<Pricing />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/GetAQuotes" element={<GetAQuotes />} />
      <Route path="/ServiceDetails" element={<ServiceDetails />} />
      <Route path="/Shipment" element={<Shipment />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/DashBoard" element={<DashBoard />} />
      {/*     
      <Route path="/Test" element={<Test />} /> */}
    </Routes>
  );
}
export default App;
