import React from 'react';

const Footer = () => {
  return (
    <div>
      <footer id="footer" className="footer">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="/" className="logo d-flex align-items-center">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'}
                  alt=""
                />
                {/* <span>AXL</span> */}
              </a>
              <p>
                We provide cost-effective solutions and achieve better results
                each time to match client requirement. Managed by a team of
                seasoned professionals, efficient & well-trained staff and
                supported by a well-maintained fleet of vehicles
              </p>
              <div className="social-links d-flex mt-4">
                <a href="#" className="twitter">
                  <i className="bi bi-twitter" />
                </a>
                <a href="#" className="facebook">
                  <i className="bi bi-facebook" />
                </a>
                <a href="#" className="instagram">
                  <i className="bi bi-instagram" />
                </a>
                <a href="#" className="linkedin">
                  <i className="bi bi-linkedin" />
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/About">About us</a>
                </li>
                <li>
                  <a href="/Services">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="/Pricing">Pricing</a>
                </li>
                <li>
                  <a href="#">Fleet Details</a>
                </li>
                <li>
                  <a href="#">Clients</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <a href="/GetAQuotes">@Get a Quote</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
                Ground Floor, G-3, New No.1,
                <br/>
                 Temple Arcade, 8th Main, 2nd Cross.
                Duggalama Temple Road,
                <br/>
                 Laggere, Rajagopalnagar, Peenya 3rd
                Stage, Bangalore 560058
                <br />
                Karnataka <br />
                India <br />
                <br />
                <strong>Phone:</strong> +1 5589 55488 55
                <br />
                <strong>Email:</strong> info@example.com
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="container mt-4">
          <div className="copyright">
            © Copyright{' '}
            <strong>
              <span>AXL</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            {/* All the links in the footer should remain intact. */}
            {/* You can delete the links only if you purchased the pro version. */}
            {/* Licensing information: https://bootstrapmade.com/license/ */}
            {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/logis-bootstrap-logistics-website-template/ */}
            Designed by <a href="https://transprofessionals.com/">@transPro</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
