import React, { StrictMode, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {
  setUserRole,
  setToken,
  setLoading,
  initializeAuth,
} from '../store/authSlice';
import store from '../store/store';
// import {
//   Alert,
//   AlertTitle,
//   AlertIcon,
//   AlertDescription,
// } from '@chakra-ui/alert';

import '../css/dashBoard.css';
import {
  CreateBookingForm,
  DisplayBooking,
  UpdateBooking,
  DeleteBooking,
  ALLBookings,
  UpdateStatus,
  MyBookings
} from './Bookings';

const DashBoard = () => {
  const [activeTab, setActiveTab] = useState('booking');
  const [userRole, setUserRole] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async () => {
      // dispatch({
      //   type: 'auth/initialize',
      //   // payload: {},
      //   payload: { userRole: 'user', token: 'defaultToken', isLoading: true },
      // });

      dispatch(
        initializeAuth({
          userRole: 'user',
          token: 'defaultToken',
          isLoading: true,
        })
      );
      console.log('@@@@@@@@@@@@@ INITIALIZE TO LOGIN ###################');
      // }, [dispatch]);
      const state = store.getState();
      const userRoleRedux = state.auth.userRole;
      const tokenRedux = state.auth.token;
      const loading = state.auth.isLoading;

      setUserRole(userRoleRedux);
      setToken(tokenRedux);
      setLoading(loading);

      // const tokenRedux = useSelector((state) => state.auth.token);
      // const userRoleRedux = useSelector((state) => state.auth.userRole);
      // const loading = useSelector((state) => state.auth.isLoading);

      console.log('@@@@@@@@@@@@@', userRoleRedux, '###################');
      console.log('@@@@@@@@@@@@@', tokenRedux, '###################');
      console.log('@@@@@@@@@@@@@', loading, '###################');

      // useEffect(() => {
      if (tokenRedux === 'defaultToken') {
        // If userRoleRedux is not available, redirect back to the login page
        console.log('@@@@@@@@@@@@@ BACK TO LOGIN ###################');
        alert('You don not have Login credentials, please login first');

        navigate('/Login');
      } else {
        // Set loading to false when the user role is available
        setLoading(false);

        console.log('@@@@@@@@@@@@@ WE CAN LOGIN ###################');
      }
    };
    initialize();
  }, [navigate, dispatch]);

  const handleTabClick = (tabName) => {
    console.log('@@@@@@@@@@@@@', tabName, '###################');
    setActiveTab(tabName);
    // switch (tabName) {
    //   case 'createBooking':
    //     return <CreateBookingForm/>
    //     break;

    //   default:
    //     break;
    // }
  };

  if (loading) {
    // Render a loading state while waiting for userRoleRedux to be available
    return <div>Loading...</div>;
  }
  return (
    <StrictMode>
      <section className="dvh-100" style={{ backgroundColor: '#0e1d34' }}>
        <div className="dashboard">
          <div className="card">
            <div className="header container-fluid container-xl d-flex align-items-center justify-content-between fixed-top">
              <a href="/" className="logo d-flex align-items-center">
                {/* Uncomment the line below if you also wish to use an image logo */}
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'}
                  alt=""
                />
              </a>
            </div>
            <div className="bookCard-header">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item">
                  <button
                    className={`bookNav-link ${
                      activeTab === 'booking' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('booking')}
                  >
                    Booking
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`bookNav-link ${
                      activeTab === 'finance' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('finance')}
                  >
                    Finance
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`bookNav-link ${
                      activeTab === 'maps' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('maps')}
                  >
                    Maps
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`bookNav-link ${
                      activeTab === 'vehicle' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('vehicle')}
                  >
                    Vehicle
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={`bookNav-link ${
                      activeTab === 'admin' ? 'active' : ''
                    }`}
                    onClick={() => handleTabClick('admin')}
                  >
                    Admin
                  </button>
                </li>
              </ul>
            </div>
            <div className="bookCard-body">
              {activeTab === 'booking' && (
                <div className="bookCard-header card-header-tabs">
                  <h5 className="text-primary">Booking Content</h5>
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('createBooking')}
                      >
                        Create Booking
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('updateBooking')}
                      >
                        Update Booking
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('displayBooking')}
                      >
                        Display Booking
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('deleteBooking')}
                      >
                        Delete Booking
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('allBookings')}
                      >
                        All Bookings
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('updateStatus')}
                      >
                        Update Status
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('myBookings')}
                      >
                        My Bookings
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('userBookings')}
                      >
                        User's Booking
                      </button>
                    </li>

                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('assignBooking')}
                      >
                        Assign Booking
                      </button>
                    </li>
                    <li className="nav-item">
                      <button
                        className="bookNav-link"
                        onClick={() => handleTabClick('addLocation')}
                      >
                        Add Location
                      </button>
                    </li>
                  </ul>
                </div>
              )}
              {/* Render CreateBookingForm when activeTab is 'createBooking' */}
              {activeTab === 'createBooking' && (
                <div>
                  <CreateBookingForm />
                </div>
              )}
              {/* Render DisplayBooking when activeTab is 'displayBooking' */}
              {activeTab === 'displayBooking' && (
                <div>
                  <DisplayBooking />
                </div>
              )}
              {/* Render UpdateBooking when activeTab is 'UpdateBooking' */}
              {activeTab === 'updateBooking' && (
                <div>
                  <UpdateBooking />
                </div>
              )}
              {/* Render deleteBooking when activeTab is 'deleteBooking' */}
              {activeTab === 'deleteBooking' && (
                <div>
                  <DeleteBooking />
                </div>
              )}
              {/* Render allBookings when activeTab is 'allBookings' */}
              {activeTab === 'allBookings' && (
                <div>
                  <ALLBookings />
                </div>
              )}
              {/* Render updateStatus when activeTab is 'updateStatus' */}
              {activeTab === 'updateStatus' && (
                <div>
                  <UpdateStatus />
                </div>
              )}
                {/* Render updateStatus when activeTab is 'updateStatus' */}
              {activeTab === 'myBookings' && (
                <div>
                  <MyBookings />
                </div>
              )}
              {activeTab === 'finance' && (
                <div>
                  <h5>Finance Content</h5>
                  {/* Add additional tabs and content specific to Finance */}
                </div>
              )}
              {activeTab === 'maps' && (
                <div>
                  <h5>Maps Content</h5>
                  {/* Add additional tabs and content specific to Maps */}
                </div>
              )}
              {activeTab === 'vehicle' && (
                <div>
                  <h5>Vehicle Content</h5>
                  {/* Add additional tabs and content specific to Vehicle */}
                </div>
              )}
              {activeTab === 'admin' && (
                <div>
                  <h5>Admin Content</h5>
                  {/* Add additional tabs and content specific to Admin */}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </StrictMode>
  );
};

export default DashBoard;
