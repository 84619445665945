/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from '../components/Footer'
import Shipment from './Shipment'
import { useDispatch } from 'react-redux';
import { initializeAuth } from '../store/authSlice';
const Home = (props) => {
  const [selected, setSelected] = useState(0);
  // const [seconds, setSeconds] = useState(0);
  const[value, setValue] = useState(""); 

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeAuth({
      userRole: 'newUserRole',
      token: 'newToken',
      isLoading: true,
    }));
    localStorage.clear();
  }, []);


  const img1 = process.env.PUBLIC_URL + '/assets/img/indiamap.png';
  const img2 = process.env.PUBLIC_URL + '/assets/img/aircargo.png';
  const img3 = process.env.PUBLIC_URL + '/assets/img/truckscargo.png';
  const img4 = process.env.PUBLIC_URL + '/assets/img/container-ship.png';
  const img5 = process.env.PUBLIC_URL + '/assets/img/locamotiveCargo.png';

  const imgArr = [img1, img2, img3, img4, img5];
  let img = img1;

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      console.log('This will run every 5 second!', count + 5);

      const randomNumber = Math.floor(Math.random() * imgArr.length);
      console.log('This will run every 6 second!', randomNumber);

      // for (let i = 0; i < imgArr.length; i++) {
      //   setSelected(i);

      // }
      setSelected(randomNumber);

      // setSeconds((seconds) => seconds + 1);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  const trackShipment = ()=> {

    const cnNumber = value;
   
      // alert(`This is Given cn Number ${cnNumber}`);
    console.log(value);
   
        // return(
          
          // <Shipment cnNumber={cnNumber}/> 
        // )   
           window.open(`/Shipment?cnNumber=${cnNumber}`);
          
          // props.navigation.navigate('/Shipment');
    

    

}

  img = imgArr[selected];

  return (
    <div>
    <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <title>Logis Bootstrap Template - About</title>
      <meta content name="description" />
      <meta content name="keywords" />
      {/* <meta content=true  /> */}
      {/* Favicons */}
       <link href="assets/img/favicon.png" rel="icon" />
      <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
      {/* Google Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />
      {/* Vendor CSS Files */}
     <link
        href="assets/vendor/bootstrap/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/fontawesome-free/css/all.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/glightbox/css/glightbox.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/swiper/swiper-bundle.min.css"
        rel="stylesheet"
      />
      <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
      <link href="assets/css/main.css" rel="stylesheet" />
      <script src="https://code.jquery.com/jquery-3.5.1.js"></script>
      
      {/* ======= Header ======= */}
      <header
        id="header"
        className="header  d-flex align-items-center fixed-top sticked"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img
              src={process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'}
              alt=""
            />
            {/* <h1>AXL</h1> */}
          </a>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a href="/" className="active">
                  Home
                </a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/services">Services</a>
              </li>
              <li>
                <a href="/pricing">Pricing</a>
              </li>
              <li className="dropdown">
                <a href="#">
                  <span>Drop Down</span>{' '}
                  <i className="bi bi-chevron-down dropdown-indicator" />
                </a>
                <ul>
                  <li>
                    <a href="#">Drop Down 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>Deep Drop Down</span>{' '}
                      <i className="bi bi-chevron-down dropdown-indicator" />
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Drop Down 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Drop Down 2</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 3</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 4</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/Contact">Contact</a>
              </li>
              <li>
                <a className="get-a-quote" href="/GetAQuotes">
                  Get a Quote
                </a>
              </li>
              <li>
                <a className="get-a-quote" href="/Login">
                  Login
                </a>
              </li>
            </ul>
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      {/* End Header */}
      {/* ======= Hero Section ======= */}
      <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-between">
            <div className="col-lg-6 order-2 order-lg-1 d-flex flex-column justify-content-center">
              <h2 data-aos="fade-up">Your Lightning Fast Delivery Partner</h2>
              <p data-aos="fade-up" data-aos-delay={100}>
                We provide cost-effective solutions and achieve better results
                each time to match client requirement. Managed by a team of
                seasoned professionals, efficient & well-trained staff and
                supported by a well-maintained fleet of vehicles.
              </p>
              <form
                action="#"
                className="form-search d-flex align-items-stretch mb-3"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="TRACK YOUR SHIPMENT"
                  value={value} onChange={(e) => {setValue(e.target.value)}}
                />
                <button type="submit" className="btn btn-primary" onClick={trackShipment}>
                  Search
                </button>
              </form>
              <div className="row gy-4" data-aos="fade-up" data-aos-delay={400}>
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={232}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      {232}
                    </span>
                    <p>Clients</p>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={521}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      521
                    </span>
                    <p>Projects</p>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={1453}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      1453
                    </span>
                    <p>Support</p>
                  </div>
                </div>
                {/* End Stats Item */}
                <div className="col-lg-3 col-6">
                  <div className="stats-item text-center w-100 h-100">
                    <span
                      data-purecounter-start={0}
                      data-purecounter-end={32}
                      data-purecounter-duration={1}
                      className="purecounter"
                    >
                      32
                    </span>
                    <p>Workers</p>
                  </div>
                </div>
                {/* End Stats Item */}
              </div>
            </div>
            <div
              className="col-lg-5 order-1 order-lg-2 hero-img"
              data-aos="zoom-out"
            >
              <img
                src={img}
                // src={process.env.PUBLIC_URL + '/assets/img/indiamap.png'}
                className="img-fluid mb-3 mb-lg-0"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* End Hero Section */}
      <main id="main">
        {/* ======= Featured Services Section ======= */}
        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-cart-flatbed" />
                </div>
                <div>
                  <h4 className="title">Surface Services:</h4>
                  <p className="description">
                    Full truckload/Full container load/Less than container load.
                    <br />
                    Movements with 50 dedicated fleet of vehicles.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-truck" />
                </div>
                <div>
                  <h4 className="title"> Door-to-door Services:</h4>
                  <p className="description">
                    Air Services: - Door-to-door & airport-to-airport economical
                    freight solutions.
                    <br></br>
                    Rail Services: - we offer both SLR & VPU facilities with
                    door-to-door pickup and delivery.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-truck-ramp-box" />
                </div>
                <div>
                  <h4 className="title"> 3PL/Warehouse Services:</h4>
                  <p className="description">
                    We provide industry specific economic warehousing services.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
            </div>
          </div>
        </section>
        {/* End Featured Services Section */}
        {/* ======= About Us Section ======= */}
        <section id="about" className="about pt-0">
          <div className="container" data-aos="fade-up">
            <div className="row gy-4">
              <div className="col-lg-6 position-relative align-self-start order-lg-last order-first">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/about.jpg'}
                  className="img-fluid"
                  alt=""
                />
                <a
                  href="https://www.youtube.com/watch?v=M9bhCVqGaOI"
                  className="glightbox play-btn"
                />
              </div>
              <div className="col-lg-6 content order-last  order-lg-first">
                <h3>About Us</h3>
                <p>
                  ADI EXPRESS LOGISTICS – Started in the year 2020 with an
                  objective to provide “On Time” and “Cost effective” logistics
                  solutions to our customers. AXL is part of Transprofessionals
                  Tours and Travels Pvt Ltd, a company having almost 15 years’
                  experience in professional transport service
                </p>
                <ul>
                  <li data-aos="fade-up" data-aos-delay={100}>
                    <i className="bi bi-diagram-3" />
                    <div>
                      <h5>Logistics Network Across Country</h5>
                      <p>
                        Our network which links manufacturers, suppliers,
                        wholesalers, retailers and consumers.
                      </p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay={200}>
                    <i className="bi bi-fullscreen-exit" />
                    <div>
                      <h5>
                        Logistics Planning and Network Distribution Strategy
                      </h5>
                      <p>
                        Our distribution centre, warehouse and Transport
                        operations, which links manufacturers to end consumers
                        directly .
                      </p>
                    </div>
                  </li>
                  <li data-aos="fade-up" data-aos-delay={300}>
                    <i className="bi bi-broadcast" />
                    <div>
                      <h5>
                        Supply chain and Logistics Distribution Specialists
                      </h5>
                      <p>
                        We have a specialized Team, Our expertise are in. All
                        aspects of Supply Chain,Logistics Operations,
                        Warehousing Operations, e-commerce Logistics and
                        Distribution Centre Design.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* End About Us Section */}
        {/* ======= Services Section ======= */}
        <section id="service" className="services pt-0">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Our Services</span>
              <h2>Our Services</h2>
            </div>
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/storage-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Storage
                    </a>
                  </h3>
                  <p>
                    We offer customer specific storage services to take out the
                    complexity of your supply chain so you can focus on growing
                    your business. Whether you need temporary storage services,
                    shipping and fulfillment solutions for your warehousing and
                    distribution services, we have got specific solutions your
                    requirements!
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/logistics-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Logistics
                    </a>
                  </h3>
                  <p>
                    We are having complex logistics networks across large
                    geographies. Multiple teams following largely automated
                    processes are able to track and report on the status of
                    shipments to end customers. With our efficient service
                    customer will not suffering any SLA breaches and incur
                    reduced operational costs.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/img/cargo-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Cargo
                    </a>
                  </h3>
                  <p>
                    We Provide Solution for all Logistics needs of our
                    customers. Fulfil your container shipping requirements and
                    get instant shipping quote for your ocean freight. We
                    provides best freight charges for international shipping as
                    well as multiple secure payment options for container
                    booking along with advanced container tracking.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/trucking-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Trucking
                    </a>
                  </h3>
                  <p>
                    We Provide services @ lowest rates of booking with our
                    Cargoes Logistics. Fast, Safe & Secure Shipments. Efficient
                    movement of Cargo in a seamless and secured way from origin
                    to destination. FTL/FCL/LCL services with movements around
                    50 dedicated fleet of vehicles with GPS Tracking application
                    system.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/packaging-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Packaging
                    </a>
                  </h3>
                  <p>
                    We have one stop shop solution for your industrial packaging
                    needs. Our services range spans all types of packaging
                    containers, We offer both standard and customized solutions
                    for your transportation and storage needs.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/warehousing-service.jpg'
                      }
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Warehousing
                    </a>
                  </h3>
                  <p>
                    <br />
                    3PL/Warehouse Services: - We provide industry specific
                    economic warehousing services.
                    <br />
                    A. Consolidate: Connected your store with our facilities,
                    Store your products and send them to our warehouse.
                    <br />
                    B. Pile: Store your inventory with us in any of our HUB
                    centers.
                    <br />
                    C. Transfer: Ship directly to your customers within few
                    hours of order placement.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
            </div>
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Call To Action Section ======= */}
        <section id="call-to-action" className="call-to-action">
          <div className="container" data-aos="zoom-out">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h3>Call To Action</h3>
                <p>
                  {' '}
                  Enroll to Our Services for Fast, Safe & Secure Shipments.
                  Efficient movement of Cargo in a seamless and secured way from
                  origin to destination..
                </p>
                <a className="cta-btn" href="#">
                  Call To Action
                </a>
              </div>
            </div>
          </div>
        </section>
        {/* End Call To Action Section */}
        {/* ======= Features Section ======= */}
        <section id="features" className="features">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/features-1.jpg'}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>
                  Standard and customized solutions for your transportation and
                  storage needs.
                </h3>
                <p className="fst-italic">
                  3PL/Warehouse Services: - We provide industry specific
                  economic warehousing services.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" /> A. Consolidate: Connected your
                    store with our facilities, Store your products and send them
                    to our warehouse.
                  </li>
                  <li>
                    <i className="bi bi-check" /> B. Pile: Store your inventory
                    with us in any of our HUB centers.
                  </li>
                  <li>
                    <i className="bi bi-check" /> C. Transfer: Ship directly to
                    your customers within few hours of order placement.
                  </li>
                </ul>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/features-2.jpg'}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3> Live Tracking web and mobile application</h3>
                <p className="fst-italic">
                  Our services include live tracking your consignments using
                  google maps.
                </p>
                <p>
                  We have a Mobile/Web application which tracks the live
                  location of the consignments and provides exact location
                  details and dispatch the status details of the consignments.
                  Provided CN-Number are used to track and update the data
                  continuously. With advanced technologies backing our services,
                  Our customer will not suffering any SLA breaches and not incur
                  any loss in the operational costs.
                </p>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/features-3.jpg'}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>
                  Specialized Team, With Logistics Domaine expertise in Supply
                  Chain, Warehousing and e-commerce Logistics.
                </h3>
                <p>
                  we believe that customer satisfaction is as important as our
                  service, this has helped us to establish a firm foothold in
                  the logistics industry. Our employees are dedicated to their
                  respective roles and put a lot of effort to achieve the common
                  vision and larger goals of the company.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" /> # Customer specific service
                    levels. #24/7 GPS Monitoring.
                  </li>
                  <li>
                    <i className="bi bi-check" /> # tracking and billing purpose
                    Proactive data mining and MIS team. # Operation team headed
                    by domain experts in staff logistics services.
                  </li>
                  <li>
                    <i className="bi bi-check" /> # Well maintained statutory,
                    safety compliance, # Effective communication system for
                    drivers, # Provide vehicle and driver’s records to clients,
                    # Training and periodic reorientation for chauffers by Rtd
                    RTO inspectors.
                  </li>
                </ul>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src={process.env.PUBLIC_URL + '/assets/img/features-4.jpg'}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>Areas of Operations</h3>
                <p className="fst-italic">
                  Our Area of Operation spans across several geographical
                  location, which includes, Strong hold in southern, Northern
                  and western parts of India.
                </p>
                <p>
                  Major cities serviced are - Chennai, Coimbatore, Hyderabad,
                  Vijayawada, Vishakhapatnam, Ernakulam, Delhi, NCR, Ludhiana,
                  Faridabad, Chandigarh, Lucknow, Ghaziabad, Haridwar, Roorkee,
                  Mumbai, Pune, Surat, Ahmedabad and Kolkata.
                </p>
              </div>
            </div>
            {/* Features Item */}
          </div>
        </section>
        {/* End Features Section */}
        {/* ======= Pricing Section ======= */}
        {/* <section id="pricing" className="pricing pt-0"> */}
          {/* <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Pricing</span>
              <h2>Pricing</h2>
            </div>
            <div className="row gy-4">
              <div className="col-lg-4" data-aos="fade-up" data-aos-delay={100}>
                <div className="pricing-item">
                  <h3>Free Plan</h3>
                  <h4>
                    <sup>$</sup>0<span> / month</span>
                  </h4>
                  <ul>
                    <li>
                      <i className="bi bi-check" /> Quam adipiscing vitae proin
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nec feugiat nisl pretium
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nulla at volutpat diam
                      uteera
                    </li>
                    <li className="na">
                      <i className="bi bi-x" />{' '}
                      <span>Pharetra massa massa ultricies</span>
                    </li>
                    <li className="na">
                      <i className="bi bi-x" />{' '}
                      <span>Massa ultricies mi quis hendrerit</span>
                    </li>
                  </ul>
                  <a href="#" className="buy-btn">
                    Buy Now
                  </a>
                </div>
              </div>
              {/* End Pricing Item */}
              {/* <div className="col-lg-4" data-aos="fade-up" data-aos-delay={200}>
                <div className="pricing-item featured">
                  <h3>Business Plan</h3>
                  <h4>
                    <sup>$</sup>29<span> / month</span>
                  </h4>
                  <ul>
                    <li>
                      <i className="bi bi-check" /> Quam adipiscing vitae proin
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nec feugiat nisl pretium
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nulla at volutpat diam
                      uteera
                    </li>
                    <li>
                      <i className="bi bi-check" /> Pharetra massa massa
                      ultricies
                    </li>
                    <li>
                      <i className="bi bi-check" /> Massa ultricies mi quis
                      hendrerit
                    </li>
                  </ul>
                  <a href="#" className="buy-btn">
                    Buy Now
                  </a>
                </div>
              </div> */}
              {/* End Pricing Item */}
              {/* <div className="col-lg-4" data-aos="fade-up" data-aos-delay={300}>
                <div className="pricing-item">
                  <h3>Developer Plan</h3>
                  <h4>
                    <sup>$</sup>49<span> / month</span>
                  </h4>
                  <ul>
                    <li>
                      <i className="bi bi-check" /> Quam adipiscing vitae proin
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nec feugiat nisl pretium
                    </li>
                    <li>
                      <i className="bi bi-check" /> Nulla at volutpat diam
                      uteera
                    </li>
                    <li>
                      <i className="bi bi-check" /> Pharetra massa massa
                      ultricies
                    </li>
                    <li>
                      <i className="bi bi-check" /> Massa ultricies mi quis
                      hendrerit
                    </li>
                  </ul>
                  <a href="#" className="buy-btn">
                    Buy Now
                  </a>
                </div>
              </div> */}
              {/* End Pricing Item */}
            {/* </div>
          </div> */}
        {/* </section>  */}
        {/* End Pricing Section */}
        {/* ======= Testimonials Section ======= */}
        {/* <section id="testimonials" className="testimonials">
          <div className="container">
            <div className="slides-1 swiper" data-aos="fade-up">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/testimonials/testimonials-1.jpg'
                      }
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Proin iaculis purus consequat sem cure digni ssim donec
                      porttitora entum suscipit rhoncus. Accusantium quam,
                      ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                      risus at semper.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
                {/* <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/testimonials/testimonials-2.jpg'
                      }
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Export tempor illum tamen malis malis eram quae irure esse
                      labore quem cillum quid cillum eram malis quorum velit
                      fore eram velit sunt aliqua noster fugiat irure amet legam
                      anim culpa.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div> */}
                {/* End testimonial item */}
                {/* <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/testimonials/testimonials-3.jpg'
                      }
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Enim nisi quem export duis labore cillum quae magna enim
                      sint quorum nulla quem veniam duis minim tempor labore
                      quem eram duis noster aute amet eram fore quis sint minim.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div> */}
                {/* End testimonial item */}
                {/* <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/testimonials/testimonials-4.jpg'
                      }
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                      multos export minim fugiat minim velit minim dolor enim
                      duis veniam ipsum anim magna sunt elit fore quem dolore
                      labore illum veniam.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div> */}
                {/* End testimonial item */}
                {/* <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        '/assets/img/testimonials/testimonials-5.jpg'
                      }
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Quis quorum aliqua sint quem legam fore sunt eram irure
                      aliqua veniam tempor noster veniam enim culpa labore duis
                      sunt culpa nulla illum cillum fugiat legam esse veniam
                      culpa fore nisi cillum quid.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div> */}
                {/* End testimonial item */}
              {/* </div>
              <div className="swiper-pagination" />
            </div>
          </div> */}
        {/* </section> */} 
        {/* End Testimonials Section */}
        {/* ======= Frequently Asked Questions Section ======= */}
        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Frequently Asked Questions</span>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div
              className="row justify-content-center"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="col-lg-10">
                <div className="accordion accordion-flush" id="faqlist">
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        How would I know my fare charges & bill amount?
                      </button>
                    </h3>
                    <div
                      id="faq-content-1"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        After we received your booking confirmation into our
                        system, you will be notified of the approximate distance
                        that your goods will travel, and accordingly your bill
                        amount will be calculated. With our fare calculator, we
                        instantaneously give you the best rate.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Is there a possibility of the truck being a no-show once
                        the booking has been confirmed?
                      </button>
                    </h3>
                    <div
                      id="faq-content-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        We are committed to fulfil our responsibility toward all
                        confirmed bookings. In the event of not being able to do
                        so due to unforeseen circumstances, you will be notified
                        well ahead of the pick-up time.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-3"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        What are the Payment terms?
                      </button>
                    </h3>
                    <div
                      id="faq-content-3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        In case of Advance booking customers have to pay a token
                        amount via NEFT / IMPS / PAYTM for booking confirmation.
                        Once the truck arrives pickup location customers has to
                        pay 90 % at the time of loading and balance amount at
                        the time of arrival before unloading.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-4"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        What are the GST charges on Fare amount ?
                      </button>
                    </h3>
                    <div
                      id="faq-content-4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        <i className="bi bi-question-circle question-icon" />
                        GST is Payable on Reverse Charge Mechanism: GST @ 5%
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-5"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Who will Generate an e-Way Bill ?
                      </button>
                    </h3>
                    <div
                      id="faq-content-5"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Consignor or Consignee before movement of goods needs to
                        Generate Part A & B e-way bill. Eway Bill is not
                        required for household items
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Frequently Asked Questions Section */}
      </main>
      {/* End #main */}
      {/* ======= Footer ======= */}
   <Footer/>
      {/* End Footer */}

      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* <div id="preloader" /> */}
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default Home;
