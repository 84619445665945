import React from 'react';
import Footer from '../components/Footer';

const Services = () => {
  return (
    <div>
      <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <title>Logis Bootstrap Template - Services</title>
      <meta content name="description" />
      <meta content name="keywords" />
      {/* Favicons */}
      <link href="assets/img/favicon.png" rel="icon" />
      <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
      {/* Google Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />
      {/* Vendor CSS Files */}
      <link
        href="assets/vendor/bootstrap/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/fontawesome-free/css/all.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/glightbox/css/glightbox.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/swiper/swiper-bundle.min.css"
        rel="stylesheet"
      />
      <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
      {/* Template Main CSS File */}
      <link href="assets/css/main.css" rel="stylesheet" />
      {/* =======================================================
      * Template Name: Logis - v1.2.1
      * Template URL: https://bootstrapmade.com/logis-bootstrap-logistics-website-template/
      * Author: BootstrapMade.com
      * License: https://bootstrapmade.com/license/
      ======================================================== */}
      {/* ======= Header ======= */}
      <header
        id="header"
        className="header d-flex align-items-center fixed-top sticked"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img
              src={process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'}
              alt=""
            />
            {/* <h1>Logis</h1> */}
          </a>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/About">About</a>
              </li>
              <li>
                <a href="/Services" className="active">
                  Services
                </a>
              </li>
              <li>
                <a href="/Pricing">Pricing</a>
              </li>
              <li className="dropdown">
                <a href="#">
                  <span>Drop Down</span>{' '}
                  <i className="bi bi-chevron-down dropdown-indicator" />
                </a>
                <ul>
                  <li>
                    <a href="#">Drop Down 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#">
                      <span>Deep Drop Down</span>{' '}
                      <i className="bi bi-chevron-down dropdown-indicator" />
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Drop Down 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Drop Down 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Drop Down 2</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 3</a>
                  </li>
                  <li>
                    <a href="#">Drop Down 4</a>
                  </li>
                </ul>
              </li>
              <li>
                <a href="/Contact">Contact</a>
              </li>
              <li>
                <a className="get-a-quote" href="/GetAQuotes">
                  Get a Quote
                </a>
              </li>
            </ul>
          </nav>
          {/* .navbar */}
        </div>
      </header>
      {/* End Header */}
      {/* End Header */}
      <main id="main">
        {/* ======= Breadcrumbs ======= */}
        <div className="breadcrumbs">
          <div
            className="page-header d-flex align-items-center"
            style={{ backgroundImage: 'url("assets/img/page-header.jpg")' }}
          >
            <div className="container position-relative">
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6 text-center">
                  <h2>Services</h2>
                  <p>
                    Our services are all the elements of your supply chain, from
                    the Manufacturing Source to the end customer. They include
                    transportation from manufacturer hub to warehouse,
                    warehousing and order fulfillment, and delivery to the end
                    customer.
                    <br />
                    Our services include: Transport from the Manufacturing
                    Source to the warehouse fulfillment. Warehousing and order
                    fulfillment. Outbound shipping and order delivery to end
                    customers.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <nav>
            <div className="container">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Services</li>
              </ol>
            </div>
          </nav>
        </div>
        {/* End Breadcrumbs */}
        {/* ======= Featured Services Section ======= */}
        <section id="featured-services" className="featured-services">
          <div className="container">
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-cart-flatbed" />
                </div>
                <div>
                  <h4 className="title">Surface Services:</h4>
                  <p className="description">
                    Full truckload/Full container load/Less than container load.
                    <br />
                    Movements with 50 dedicated fleet of vehicles.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-truck" />
                </div>
                <div>
                  <h4 className="title">Door-to-door Services:</h4>
                  <p className="description">
                    Air Services: - Door-to-door & airport-to-airport economical
                    freight solutions.
                    <br />
                    Rail Services: - we offer both SLR & VPU facilities with
                    door-to-door pickup and delivery.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
              <div
                className="col-lg-4 col-md-6 service-item d-flex"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="icon flex-shrink-0">
                  <i className="fa-solid fa-truck-ramp-box" />
                </div>
                <div>
                  <h4 className="title">3PL/Warehouse Services:</h4>
                  <p className="description">
                    We provide industry specific economic warehousing services.
                  </p>
                  <a href="/ServiceDetails" className="readmore stretched-link">
                    <span>Learn More</span>
                    <i className="bi bi-arrow-right" />
                  </a>
                </div>
              </div>
              {/* End Service Item */}
            </div>
          </div>
        </section>
        {/* End Featured Services Section */}
        {/* ======= Services Section ======= */}
        <section id="service" className="services pt-0">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Our Services</span>
              <h2>Our Services</h2>
            </div>
            <div className="row gy-4">
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={100}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/storage-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Storage
                    </a>
                  </h3>
                  <p>
                    #We offer customer specific storage services to take out the
                    complexity of your supply chain so you can focus on growing
                    your business. Whether you need temporary storage services,
                    shipping and fulfillment solutions for your warehousing and
                    distribution services, we have got specific solutions your
                    requirements!
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={200}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/logistics-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Logistics
                    </a>
                  </h3>
                  <p>
                    #We are having complex logistics networks across large
                    geographies. Multiple teams following largely automated
                    processes are able to track and report on the status of
                    shipments to end customers. With our efficient service
                    customer will not suffering any SLA breaches and incur
                    reduced operational costs.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/cargo-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Cargo
                    </a>
                  </h3>
                  <p>
                    #We Provide Solution for all Logistics needs of our
                    customers. Fulfil your container shipping requirements and
                    get instant shipping quote for your ocean freight. We
                    provides best freight charges for international shipping as
                    well as multiple secure payment options for container
                    booking along with advanced container tracking.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={400}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/trucking-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Trucking
                    </a>
                  </h3>
                  <p>
                    #We Provide services @ lowest rates of booking with our
                    Cargoes Logistics. Fast, Safe & Secure Shipments. Efficient
                    movement of Cargo in a seamless and secured way from origin
                    to destination. FTL/FCL/LCL services with movements around
                    50 dedicated fleet of vehicles with GPS Tracking application
                    system.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/packaging-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Packaging
                    </a>
                  </h3>
                  <p>
                    #We have one stop shop solution for your industrial
                    packaging needs. Our services range spans all types of
                    packaging containers, We offer both standard and customized
                    solutions for your transportation and storage needs.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
              <div
                className="col-lg-4 col-md-6"
                data-aos="fade-up"
                data-aos-delay={600}
              >
                <div className="card">
                  <div className="card-img">
                    <img
                      src="assets/img/warehousing-service.jpg"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <h3>
                    <a href="/ServiceDetails" className="stretched-link">
                      Warehousing
                    </a>
                  </h3>
                  <p>
                    <br />
                    3PL/Warehouse Services: - We provide industry specific
                    economic warehousing services.
                    <br />
                    A. Consolidate: Connected your store with our facilities,
                    Store your products and send them to our warehouse.
                    <br />
                    B. Pile: Store your inventory with us in any of our HUB
                    centers.
                    <br />
                    C. Transfer: Ship directly to your customers within few
                    hours of order placement.
                  </p>
                </div>
              </div>
              {/* End Card Item */}
            </div>
          </div>
        </section>
        {/* End Services Section */}
        {/* ======= Features Section ======= */}
        <section id="features" className="features">
          <div className="container">
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src="assets/img/features-1.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>
                  Standard and customized solutions for your transportation and
                  storage needs.
                </h3>
                <p className="fst-italic">
                  3PL/Warehouse Services: - We provide industry specific
                  economic warehousing services.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" /> A. Consolidate: Connected your
                    store with our facilities, Store your products and send them
                    to our warehouse.
                  </li>
                  <li>
                    <i className="bi bi-check" /> B. Pile: Store your inventory
                    with us in any of our HUB centers.
                  </li>
                  <li>
                    <i className="bi bi-check" /> C. Transfer: Ship directly to
                    your customers within few hours of order placement.
                  </li>
                </ul>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src="assets/img/features-2.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>Live Tracking web and mobile application</h3>
                <p className="fst-italic">
                  Our services include live tracking your consignments using
                  google maps.
                </p>
                <p>
                  We have a Mobile/Web application which tracks the live
                  location of the consignments and provides exact location
                  details and dispatch the status details of the consignments.
                  Provided CN-Number are used to track and update the data
                  continuously. With advanced technologies backing our services,
                  Our customer will not suffering any SLA breaches and not incur
                  any loss in the operational costs.
                </p>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5">
                <img
                  src="assets/img/features-3.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7">
                <h3>
                  Specialized Team, With Logistics Domaine expertise in Supply
                  Chain, Warehousing and e-commerce Logistics
                </h3>
                <p>
                  we believe that customer satisfaction is as important as our
                  service, this has helped us to establish a firm foothold in
                  the logistics industry. Our employees are dedicated to their
                  respective roles and put a lot of effort to achieve the common
                  vision and larger goals of the company.
                </p>
                <ul>
                  <li>
                    <i className="bi bi-check" /> # Customer specific service
                    levels. #24/7 GPS Monitoring.
                  </li>
                  <li>
                    <i className="bi bi-check" /> # tracking and billing purpose
                    Proactive data mining and MIS team. # Operation team headed
                    by domain experts in staff logistics services.
                  </li>
                  <li>
                    <i className="bi bi-check" /> # Well maintained statutory,
                    safety compliance, # Effective communication system for
                    drivers, # Provide vehicle and driver’s records to clients,
                    # Training and periodic reorientation for chauffers by Rtd
                    RTO inspectors.
                  </li>
                </ul>
              </div>
            </div>
            {/* Features Item */}
            <div
              className="row gy-4 align-items-center features-item"
              data-aos="fade-up"
            >
              <div className="col-md-5 order-1 order-md-2">
                <img
                  src="assets/img/features-4.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-md-7 order-2 order-md-1">
                <h3>Areas of Operations</h3>
                <p className="fst-italic">
                  Our Area of Operation spans across several geographical
                  location, which includes, Strong hold in southern, Northern
                  and western parts of India.
                </p>
                <p>
                  Major cities serviced are - Chennai, Coimbatore, Hyderabad,
                  Vijayawada, Vishakhapatnam, Ernakulam, Delhi, NCR, Ludhiana,
                  Faridabad, Chandigarh, Lucknow, Ghaziabad, Haridwar, Roorkee,
                  Mumbai, Pune, Surat, Ahmedabad and Kolkata.
                </p>
              </div>
            </div>
            {/* Features Item */}
          </div>
        </section>
        {/* End Features Section */}
        {/* ======= Testimonials Section ======= */}
        <section id="testimonials" className="testimonials">
          <div className="container">
            <div className="slides-1 swiper" data-aos="fade-up">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/testimonials-1.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Saul Goodman</h3>
                    <h4>Ceo &amp; Founder</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Proin iaculis purus consequat sem cure digni ssim donec
                      porttitora entum suscipit rhoncus. Accusantium quam,
                      ultricies eget id, aliquam eget nibh et. Maecen aliquam,
                      risus at semper.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/testimonials-2.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Sara Wilsson</h3>
                    <h4>Designer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Export tempor illum tamen malis malis eram quae irure esse
                      labore quem cillum quid cillum eram malis quorum velit
                      fore eram velit sunt aliqua noster fugiat irure amet legam
                      anim culpa.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/testimonials-3.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Jena Karlis</h3>
                    <h4>Store Owner</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Enim nisi quem export duis labore cillum quae magna enim
                      sint quorum nulla quem veniam duis minim tempor labore
                      quem eram duis noster aute amet eram fore quis sint minim.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/testimonials-4.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>Matt Brandon</h3>
                    <h4>Freelancer</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Fugiat enim eram quae cillum dolore dolor amet nulla culpa
                      multos export minim fugiat minim velit minim dolor enim
                      duis veniam ipsum anim magna sunt elit fore quem dolore
                      labore illum veniam.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
                <div className="swiper-slide">
                  <div className="testimonial-item">
                    <img
                      src="assets/img/testimonials/testimonials-5.jpg"
                      className="testimonial-img"
                      alt=""
                    />
                    <h3>John Larson</h3>
                    <h4>Entrepreneur</h4>
                    <div className="stars">
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                      <i className="bi bi-star-fill" />
                    </div>
                    <p>
                      <i className="bi bi-quote quote-icon-left" />
                      Quis quorum aliqua sint quem legam fore sunt eram irure
                      aliqua veniam tempor noster veniam enim culpa labore duis
                      sunt culpa nulla illum cillum fugiat legam esse veniam
                      culpa fore nisi cillum quid.
                      <i className="bi bi-quote quote-icon-right" />
                    </p>
                  </div>
                </div>
                {/* End testimonial item */}
              </div>
              <div className="swiper-pagination" />
            </div>
          </div>
        </section>
        {/* End Testimonials Section */}
        {/* ======= Frequently Asked Questions Section ======= */}
        <section id="faq" className="faq">
          <div className="container" data-aos="fade-up">
            <div className="section-header">
              <span>Frequently Asked Questions</span>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div
              className="row justify-content-center"
              data-aos="fade-up"
              data-aos-delay={200}
            >
              <div className="col-lg-10">
                <div className="accordion accordion-flush" id="faqlist">
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-1"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Non consectetur a erat nam at lectus urna duis?
                      </button>
                    </h3>
                    <div
                      id="faq-content-1"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Feugiat pretium nibh ipsum consequat. Tempus iaculis
                        urna id volutpat lacus laoreet non curabitur gravida.
                        Venenatis lectus magna fringilla urna porttitor rhoncus
                        dolor purus non.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-2"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Feugiat scelerisque varius morbi enim nunc faucibus a
                        pellentesque?
                      </button>
                    </h3>
                    <div
                      id="faq-content-2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-3"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi?
                      </button>
                    </h3>
                    <div
                      id="faq-content-3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Eleifend mi in nulla posuere sollicitudin aliquam
                        ultrices sagittis orci. Faucibus pulvinar elementum
                        integer enim. Sem nulla pharetra diam sit amet nisl
                        suscipit. Rutrum tellus pellentesque eu tincidunt.
                        Lectus urna duis convallis convallis tellus. Urna
                        molestie at elementum eu facilisis sed odio morbi quis
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-4"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Ac odio tempor orci dapibus. Aliquam eleifend mi in
                        nulla?
                      </button>
                    </h3>
                    <div
                      id="faq-content-4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        <i className="bi bi-question-circle question-icon" />
                        Dolor sit amet consectetur adipiscing elit pellentesque
                        habitant morbi. Id interdum velit laoreet id donec
                        ultrices. Fringilla phasellus faucibus scelerisque
                        eleifend donec pretium. Est pellentesque elit
                        ullamcorper dignissim. Mauris ultrices eros in cursus
                        turpis massa tincidunt dui.
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                  <div className="accordion-item">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq-content-5"
                      >
                        <i className="bi bi-question-circle question-icon" />
                        Tempus quam pellentesque nec nam aliquam sem et tortor
                        consequat?
                      </button>
                    </h3>
                    <div
                      id="faq-content-5"
                      className="accordion-collapse collapse"
                      data-bs-parent="#faqlist"
                    >
                      <div className="accordion-body">
                        Molestie a iaculis at erat pellentesque adipiscing
                        commodo. Dignissim suspendisse in est ante in. Nunc vel
                        risus commodo viverra maecenas accumsan. Sit amet nisl
                        suscipit adipiscing bibendum est. Purus gravida quis
                        blandit turpis cursus in
                      </div>
                    </div>
                  </div>
                  {/* # Faq item*/}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* End Frequently Asked Questions Section */}
      </main>
      {/* End #main */}
      {/* ======= Footer ======= */}
      <Footer/>
      {/* End Footer */}
      {/* End Footer */}
      <a
        href="#"
        className="scroll-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
      {/* <div id="preloader" /> */}
      {/* Vendor JS Files */}
      {/* Template Main JS File */}
    </div>
  );
};

export default Services;
