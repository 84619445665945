import React, { useState, useEffect } from 'react';
import axios from 'axios'


const Shipment = (props) => {
  const [tripArray, setTripArray] = useState([]);
  let trips = [];
  useEffect(() => {
           

    async function fetchData() {
      try {
        const Response = await axios.get("https://api.adiexpresslogistics.com/api/v1/trips");
        console.log(Response)
        if (Response) {
          console.log('#E$R#E$R#E$R==> FETCHING ALL TRIPS <==#E$R#E$R#E$R');
         
          trips = [...Response.data.data];
          setTripArray(trips);
        }
        
        // axios.get("http://65.2.171.176/api/v1/trips",{})
        // .then(async(res) => {
          //   // setTripArray(res.data)
          // console.log("DATA SUCCESSFULLY FETCHED",res.data)})
          
        } catch (err) {
          console.log(err);
          console.log('-->>', err.message, '<<--');
          // console.log(err.response.data);
          // console.log(err.response.status);
          // console.log(err.response.headers);
          alert('Can Not Perform Operation!', 'Some Thing went Wrong!! ', [
            {text: 'Okay'},
          ]);
          return;
        }
        // console.log('@#@#@#@#@#@#@', trips, '@#@#@#@#@#@#@-->1');
      }
      // fetchSingIn();
      fetchData();
    }, []);
    
    let prmStr = window.location.search.split("?");
    
    const sig = prmStr[1];
    
    console.log('#E$R#E$R#E$R==>', tripArray, '<==#E$R#E$R#E$R--->main-2');
  const cnNumber = sig?.split("=")[1];
  
  
 
  console.log("THIS IS FROM CLIENT SCREEN",cnNumber);



  let arrId = {};
  // let arrTripDelivery = {};
  let VehicleNumber = '';
  //
  for (let i = 0; i < tripArray?.length; i++) {
    // console.log('i-->', i);
    // for (let j = 0; j < tripArray[i].length; j++) {
      // console.log('j-->', j);
      // console.log(
      //   '----------->',
      //   tripArray[i].DeliveryPoint[j].TripDisplayDetails.CN_number,
      // );
      if (
        tripArray[i].CN_number ===
        cnNumber
      ) {
        console.log('IF is OK');
        arrId = tripArray[i];
        // arrTripDelivery = tripArray[i];
        if (tripArray[i]?.VehicleNumber?.VehicleNumber) {
          VehicleNumber = tripArray[i].VehicleNumber.VehicleNumber;
        } 
        // else {
        //   alert(
        //     'Can Not Display Vehicle Number!',
        //     ' Please Update Vehicle Number to the trip',
        //     [{text: 'Okay'}],
        //   );
        // }
      }
    // }
  }

  console.log('@#@#@#@#@#', arrId, '#@#@#@#@#@#@#@');
  // alert(cnNumber);


  const dateFormate = (today)=>{
    return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(today);
    // return new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(today);
  }

//  const cnDate = dateFormate(arrId.CN_Date);
  return (



    <div>
              <meta charSet="utf-8" />
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests"></meta>
      <title>Logis Bootstrap Template - About</title>
      <meta content name="description" />
      <meta content name="keywords" />
      {/* <meta content=true  /> */}
      {/* Favicons */}
      <link rel="stylesheet" href="//code.jquery.com/ui/1.8.10/themes/smoothness/jquery-ui.css" type="text/css" />
<script type="text/javascript" src="//ajax.aspnetcdn.com/ajax/jquery.ui/1.8.10/jquery-ui.min.js"></script>
       <link href="assets/img/favicon.png" rel="icon" />
      <link href="assets/img/apple-touch-icon.png" rel="apple-touch-icon" />
      {/* Google Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,600;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap"
        rel="stylesheet"
      />
      {/* Vendor CSS Files */}
     <link
        href="assets/vendor/bootstrap/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/bootstrap-icons/bootstrap-icons.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/fontawesome-free/css/all.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/glightbox/css/glightbox.min.css"
        rel="stylesheet"
      />
      <link
        href="assets/vendor/swiper/swiper-bundle.min.css"
        rel="stylesheet"
      />
      <link href="assets/vendor/aos/aos.css" rel="stylesheet" />
      <link href="assets/css/main.css" rel="stylesheet" />
      <link href="assets/css/support.css" rel="stylesheet" />
      <link href="assets/css/bootstrap.css" rel="stylesheet" />
      <script src="https://code.jquery.com/jquery-3.5.1.js"></script>
      {/* <link href="assets/css/bootstrap.mim.css" rel="stylesheet" /> */}

      <header
        id="header"
        className="header  d-flex align-items-center fixed-top sticked"
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* Uncomment the line below if you also wish to use an image logo */}
            <img
              src={process.env.PUBLIC_URL + '/assets/img/AXLLogo.png'}
              alt=""
            />
            {/* <h1>AXL</h1> */}
          </a>
          <i className="mobile-nav-toggle mobile-nav-show bi bi-list" />
          <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x" />
          <nav id="navbar" className="navbar">
            <ul>
              <li>
            
                <h1 style={{ fontWeight: "bold", fontSize: 42, color: '#00a652' }}> 
                 ADI EXPRESS LOGISTICS 
                </h1> 
              
              </li>
           
            </ul>
          </nav>
          {/* .navbar */}
        </div>
      </header>
      <div id="hides">
  <div className="panel panel-default">
    <div className="panel-heading">
      <h4 className="panel-title"  style={{ fontWeight: "bold", fontSize: 22 }}>BOOKING DETAILS</h4>
    </div>
    {/*Start WebView  Table  */}
    <div className="table-responsive visible-lg">
      <table
        className="table"
        style={{}}
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <tbody>
          <tr>
            <th>CN Date</th>
            <td id="cn_date">{arrId.CN_Date}</td>
            <th>CN Number</th>
            <td id="consigning_no">{cnNumber}</td>
          </tr>
          <tr>
            <th>From</th>
            <td id="booking_from">{arrId.BookingPoint}</td>
            <th>To</th>
            <td id="booking_to">{arrId.DeliveryPoint}</td>
          </tr>
          {/*<tr>
<th>Consignor Name</th><td id="consignor_name"></td>
<th>Consignee Name</th><td id="consignee_name"></td>
</tr>*/}
          <tr>
            {/* <th>Booking Mode</th>
            <td id="booking_mode">EXPRESS</td> */}
            <th>Status</th>
            <td
              // onClick="openWin()"
              id="booking_status"
              style={{ fontWeight: "bold", fontSize: 19 }}
            >
             {arrId.TripStatus}
              {/* <br />
              11-MAY-22 12:00AM */}
            </td>
            <th>Vehicle Number</th>
            <td id="pod_upload_dt">{VehicleNumber}</td>
          </tr>
          <tr>
            <th>POD Upload Date</th>
            <td id="pod_upload_dt">{arrId.POD_Upload_Date}</td>
            <th>ETA</th>
            <td id="eta">{arrId.ETA}</td> 
          </tr>
        </tbody>
      </table>
    </div>
    {/*End  webView  Table  */}
    {/*Start MobileView  Table  */}
    <div className="table hidden-lg">
      <table
        className="table"
        style={{}}
        cellSpacing={0}
        cellPadding={0}
        border={0}
      >
        <tbody>
          <tr>
            <th>CN Date</th>
            <td id="cn_dates">{arrId.CN_Date}</td>
          </tr>
          <tr>
            <th>CN Number</th>
            <td id="consigning_nos">{cnNumber}</td>
          </tr>
          <tr>
            <th>From</th>
            <td id="booking_froms">{arrId.BookingPoint}</td>
          </tr>
          {/*<tr><th>Consignor Name</th><td id="consignor_names"></td></tr>*/}
          <tr>
            <th>To</th>
            <td id="booking_tos">{arrId.DeliveryPoint}</td>
          </tr>
          {/*<tr><th>Consignee Name</th><td id="consignee_names"></td></tr>*/}
          {/* <tr>
            <th>Booking Mode</th>
            <td id="booking_modes">EXPRESS</td>
          </tr> */}
          <tr>
            <th>Status</th>
            <td
              // onclick="openWin()"
              id="booking_statuss"
              style={{ fontWeight: "bold", fontSize: 19 }}
            >
             {arrId.TripStatus}
             
             
            </td>
          </tr>
          <tr>
            <th>POD Upload Date</th>
            <td id="pod_upload_dts">{arrId.POD_Upload_Date}</td>
          </tr>
          <tr>
            <th>ETA</th>
            <td id="etas">{arrId.ETA}</td> 
          </tr>
        </tbody>
      </table>
    </div>
    {/*End MobileView  table */}
    <br />
    <div className="panel-heading">
      <h4 className="panel-title" style={{ fontWeight: "bold", fontSize: 22 }}>INVOICE DETAILS</h4>
    </div>
    <div className="table-responsive" id="refreshs">
      <table className="table" id="myTable" style={{}}>
        <thead>
          <tr>
            <th style={{ textAlign: "center" }}>No of Package</th>
            <th style={{ textAlign: "center" }}>Packing Type</th>
            <th style={{ textAlign: "center" }}>Item Description</th>
            <th style={{ textAlign: "center" }}>Invoice Number</th>
            <th style={{ textAlign: "center" }}>Invoice Date</th>
            <th style={{ textAlign: "center" }}>Part Number</th>
            <th style={{ textAlign: "center" }}>Quantity</th>
            <th style={{ textAlign: "center" }}>Dimension</th>
            <th style={{ textAlign: "center" }}>Weight</th>
            <th style={{ textAlign: "center" }}>E-Bill </th>
          </tr>
          <tr>
            <td>{arrId.NumberOfBox}</td>
            <td>{arrId.Packing_Type}</td>
            <td>{arrId.ItemDescription}</td>
            <td>{arrId.Invoice_Number}</td>
            <td>{arrId.Invoice_Date}</td>
            <td>{arrId.Part_Number}</td>
            <td>{arrId.Quantity}</td>
            <td>{arrId.Dimension}</td>
            <td>{arrId.Weight}</td>
            <td>{arrId.EWayBill}</td>
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }} id="tbodys"></tbody>
      </table>
    </div>
  </div>
  <div className="container" id="refresh">
    {/* Trigger the modal with a button */}
    {/* <button
      type="button"
      id="dhide"
      className="btn btn-info btn-lg" */}
      {/* // onclick="myDetail()"
    //   style={{ */}
    {/* //     textAlign: "center",
    //     background: "rgb(0, 88, 142)",
    //     display: "block"
    //   }}
    //   data-toggle="modal"
    //   data-target="#myModal"
    // >
    //   Dispatch Details
    // </button> */}
    {/* Modal */}
    <div
      className="modal fade"
      id="myModal"
      role="dialog"
      style={{ display: "none" }}
    >
      <div className="modal-dialog modal-md visible-lg">
        {/* Modal content*/}
        <div className="modal-content" style={{ borderRadius: 10 }}>
          <div className="modal-header">
            <h5 className="modal-title">Consignment Details of 24559976</h5>
            <button
              type="button"
              className="close cleardiaspatch"
              style={{ color: "red", opacity: 8 }}
              data-dismiss="modal"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <ul className="timeline">
              <li className="list deliverclass">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">11-MAY-2022</span>
                  </div>
                  <div className="desc text-success">
                    <b>DELIVERED ON-11-MAY-2022</b>
                  </div>
                </div>
              </li>
              <li className="list">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">11-MAY-2022 03:01PM</span>
                  </div>
                  <div className="desc">
                    <b>
                      MAHALUNGE NEW WH DELIVERY{" "}
                      <span style={{ color: "black !important" }}>
                        &nbsp;&nbsp;&nbsp;TO &nbsp;&nbsp;&nbsp;
                      </span>
                      OUT FOR DELIVERY
                    </b>
                  </div>
                </div>
              </li>
              <li className="list">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">05-MAY-2022 12:02PM</span>
                  </div>
                  <div className="desc">
                    <b>
                      HINJEWADI - PUNE - MAHARASTRA{" "}
                      <span style={{ color: "black !important" }}>
                        &nbsp;&nbsp;&nbsp;TO &nbsp;&nbsp;&nbsp;
                      </span>
                      MAHALUNGE NEW WH DELIVERY
                    </b>
                  </div>
                </div>
              </li>
              <li className="list">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">30-APR-2022 06:52PM</span>
                  </div>
                  <div className="desc">
                    <b>
                      BOMMANSANDRA-KARNATAKA{" "}
                      <span style={{ color: "black !important" }}>
                        &nbsp;&nbsp;&nbsp;TO &nbsp;&nbsp;&nbsp;
                      </span>
                      HINJEWADI - PUNE - MAHARASTRA
                    </b>
                  </div>
                </div>
              </li>
              <li className="list">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">28-APR-2022 08:04PM</span>
                  </div>
                  <div className="desc">
                    <b>
                      PICK FROM CLIENT{" "}
                      <span style={{ color: "black !important" }}>
                        &nbsp;&nbsp;&nbsp;TO &nbsp;&nbsp;&nbsp;
                      </span>
                      BOMMANSANDRA-KARNATAKA
                    </b>
                  </div>
                </div>
              </li>
              <li className="list">
                <div className="direction-r">
                  <div className="flag-wrapper">
                    <span className="flag">28-APR-2022</span>
                  </div>
                  <div className="desc">
                    <b>BOOKED FROM-BOMMANSANDRA-KARNATAKA</b>
                  </div>
                  <b></b>
                </div>
                <b></b>
              </li>
              <b></b>
            </ul>
          </div>
        </div>
      </div>
      {/*    end web View model box*/}
      {/* start mobile view model box */}
      <div className="modal-dialogs modal-md hidden-lg">
        {/* Modal content*/}
        <div className="modal-contents" style={{ borderRadius: 10 }}>
          <div className="modal-header">
            <h5 className="modal-title">Consignment Details of 24559976</h5>
            <button
              type="button"
              className="close cleardiaspatch"
              style={{ color: "red", opacity: 8 }}
              data-dismiss="modal"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <div className="panel panel-default " id="details"></div>
          </div>
        </div>
      </div>
      {/* end mobile view model box */}
    </div>
  </div>
</div>

     </div>
  );
};

export default Shipment;
