// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore } from 'redux-persist';
// import persistedAuthReducer from './authSlice';

// const store = configureStore({
//   reducer: {
//     auth: persistedAuthReducer,
//   },
// });

// export const persistor = persistStore(store);
// export default store;








// import {  applyMiddleware,getDefaultMiddleware } from 'redux';

// import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit';
import { configureStore,getDefaultMiddleware  } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';
import thunk from 'redux-thunk';

const middleware = [...getDefaultMiddleware(), thunk];

const store = configureStore({
  reducer: rootReducer,
  middleware,
});


export default store;


